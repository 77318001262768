<template>
  <div>
    <v-dialog v-model="visible" max-width="380" persistent>
      <v-card class="pa-4" v-if="visible">
        <v-flex>
          <div class="title mb-3">
            Confirmar celular
          </div>
          <div class="text-dark fs-sm mb-3">
            Confirme seu celular e deixe sua conta mais segura.
          </div>
          <v-form ref="form" @submit.prevent="confirmar">
            <masked-input
              v-model="phone"
              mask="(00) 00000-0000"
              label="Celular"
              maxlength="15"
              :rules="[rules.required, rules.phone_mobile_br]"
            />
            <p class="text-error fw-bold" v-if="error">{{error}}</p>
            <v-btn
              class="mb-2"
              color="primary"
              :loading="loading"
              type="submit"
              block
            >
              Enviar confirmação
            </v-btn>
            <v-btn
              @click="visible = false"
              block
              flat
            >
              Confirmar depois
            </v-btn>
          </v-form>
        </v-flex>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '~api'
import rules from '~/helpers/rules'
import maskedInput from '~/components/masked-input'

export default {
  name: 'phone-confirmation-popup',
  serverCacheKey () {
    return 'phone-confirmation-popup'
  },
  components: {
    maskedInput
  },
  data () {
    return {
      loading: false,
      phone: '',
      visible: false,
      error: null,
      rules
    }
  },
  methods: {
    async confirmar () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true
      try {
        const response = await api.auth.completeRegistration({ phone: this.phone })
        if (response.error) {
          this.error = response.error
        } else {
          this.visible = false
          this.$store.dispatch('auth/whoami')
          this.$store.commit('toast/open', { message: 'Te enviamos um SMS com link de confirmação.', color: 'success' })
          this.$emit('sent')
        }
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
    show () {
      this.visible = true
      const currentUser = this.$store.state.auth.currentUser
      if (currentUser && currentUser.cell_phone) {
        this.phone = currentUser.cell_phone
      }
    }
  }
}
</script>
