import axios from 'axios'

delete axios.defaults.headers.common.tabid

const _axios = axios.create({
  baseURL: 'https://searchbox.buser.com.br/v1/',
  withCredentials: false
})

let cancelSource = axios.CancelToken.source()

async function search (hint, from = null, to = null) {
  cancelSource.cancel()
  cancelSource = axios.CancelToken.source()

  const { data } = await _axios.get('search', {
    params: {
      q: hint,
      from_id: from,
      to_id: to
    },
    cancelToken: cancelSource.token
  }).catch(function (thrown) {
    if (!axios.isCancel(thrown)) {
      throw thrown
    }

    return { data: { items: [] } }
  })

  return data.items
}

export default { search }
