import Vue from 'vue'
import Router from 'vue-router'
import { routesVuetify } from './routes'

Vue.use(Router)

function P (page) {
  // https://router.vuejs.org/guide/advanced/lazy-loading.html
  // https://github.com/nuxt-community/router-module/issues/14
  return () => import(`./pages/${page}`).then(m => m.default || m)
}

export function createRouter () {
  return new Router({
    mode: 'history',
    routes: generateRoutes(routesVuetify)
  })
}

function generateRoutes (list) {
  return list.map(route => {
    const _route = {
      path: route.path,
      name: route.name,
      component: P(route.component),
      meta: route.meta,
      redirect: route.redirect
    }
    if (route.props) {
      _route.props = route.props
    }
    return _route
  })
}
