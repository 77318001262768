import localstorage from '~/helpers/localstorage.js'

const keyPrefix = 't_'

export const setTrechoSign = (key, value, ttl) => {
  localstorage.setLocalStorageWithExpiry(keyWithPrefix(key), value, ttl)
}

export const getTrechoSign = (key) => {
  return localstorage.getLocalStorageWithExpiry(keyWithPrefix(key))
}

const keyWithPrefix = (k) => `${keyPrefix}${k}`
