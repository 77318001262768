export const getters = {
  isFirstTravel (state, getters, rootState, rootGetters) {
    const firstTravelEnabled = rootState.settings && rootState.settings.settings && rootState.settings.settings.first_travel
    const userFirstTravel = !rootGetters['auth/loggedIn'] || rootGetters['auth/isFirstTravel']
    const searchingSoIda = (rootState.route.name === 'searchPage' || rootState.route.name === 'searchPageV1') && rootState.route.query.ida && !rootState.route.query.volta
    const isAlterarReserva = rootState.route.name === 'alterarReserva'

    return firstTravelEnabled && userFirstTravel && !searchingSoIda && !isAlterarReserva
  },
  shouldShowFreeLabelFirstTravel (state, getters, rootState) {
    return getters.isFirstTravel && !rootState.route.query.step
  }
}
