const maskPhone = (value) => {
  if (!value) return
  return value.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2 $3')
}

export default {
  initChat: (currentUser = undefined, abrirAnalistaMesmaAba, userId = undefined) => {
    if (window.HiBot !== undefined) {
      window.HiBot.start()
      return
    }
    const params = currentUser
      ? `&nome_usuario=${currentUser.name}&email=${currentUser.email}&telefone=${maskPhone(currentUser.cell_phone)}&user_id=${currentUser.id}`
      : (userId ? `&user_id=${userId}` : '')

    const url = 'https://cdn-bot.hiplatform.com/dtbot.js?token=92b1e508-27ce-4f6c-a609-0fd925a3bad0'
    // widget tem que ser false pra o chat funcionar no webview (se for true o link pra falar com o analista tenta ser aberto em uma nova aba dentro do iframe, dentro do webview e não funciona)
    const widget = abrirAnalistaMesmaAba ? '' : '&widget=true&tab=true&from=right&widgetType=circle&top=40&text=Posso%20te%20ajudar%3F&textcolor=ffffff&bgcolor=00AEEF'
    const s = document.createElement('script')
    s.src = `${url}${widget}${params}`
    s.async = true
    s.id = 'dtbot-script'
    s.onload = () => {
      window.HiBot.start()
    }
    document.body.appendChild(s)
  }
}
