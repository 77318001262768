<template>
  <v-app :class="{'has-toolbar-tabs': options.tabs, 'has-chat-help': options.chat, 'has-bottom-nav': loggedIn && !options.hideBottomNav, 'bg-black': options.dark}">
    <LazyHydrate when-idle>
      <toolbar v-show="!options.hideToolbar"/>
    </LazyHydrate>
    <toast />
    <v-content :class="{'home-page pa-0': $route.name === 'home'}">
      <nuxt />
      <floating-whatsapp-button />
    </v-content>
    <LazyHydrate when-idle>
      <phone-confirmation-alert :showAlert="options.showPhoneConfirmationAlert" @close="options.showPhoneConfirmationAlert = false" />
    </LazyHydrate>
    <LazyHydrate when-idle>
      <bottomnav />
    </LazyHydrate>
  </v-app>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import toolbar from '~/components/toolbar'
import floatingWhatsappButton from '~/components/floating-whatsapp-button'
import toast from '~/components/toast'
import bottomnav from '~/components/bottomnav'
import phoneConfirmationAlert from '~/components/login/phone-confirmation-alert'

export default {
  components: {
    LazyHydrate,
    floatingWhatsappButton,
    toolbar,
    toast,
    bottomnav,
    phoneConfirmationAlert
  },
  head () {
    const structuredData = [
      {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'Buser',
        legalName: 'Buser Brasil Tecnologia LTDA',
        logo: this.publicPath + 'buser-logo-112.png',
        image: this.publicPath + 'buser-logo-112.png',
        description: 'A Buser é uma plataforma de fretamento colaborativo que conecta pessoas interessadas na mesma viagem com empresas de transporte executivo especializadas, possibilitando viagens de ônibus mais confortáveis e seguras com economias de até 60%.',
        url: 'https://www.buser.com.br/',
        sameAs: [
          'https://facebook.com/buser.com.br',
          'https://instagram.com/buser/',
          'https://twitter.com/buserbrasil',
          'https://youtube.com/channel/UCLYHRLCxtVoLyObIHh9IboQ',
          'https://linkedin.com/company/buserbrasil/',
          'https://play.google.com/store/apps/details?id=br.com.buser.AppBuser',
          'https://apps.apple.com/br/app/buser/id1383778218'
        ],
        knowsLanguage: [
          {
            '@type': 'Language',
            name: 'Português brasileiro',
            alternateName: 'pt-BR'
          },
          {
            '@type': 'Language',
            name: 'Portuguese',
            alternateName: 'PT'
          }
        ],
        foundingDate: '2017-06-02',
        foundingLocation: [
          {
            '@type': 'Place',
            name: 'São José dos Campos - SP'
          }
        ],
        funder: [
          'SoftBank',
          'Grupo Globo',
          'Canary',
          'Yellow Ventures',
          'Fundação Estudar Alumni Partners'
        ]
      },
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        name: 'Buser',
        image: 'https://www.buser.com.br/buser-logo-112.png',
        description: 'A Buser é uma plataforma de fretamento colaborativo que conecta pessoas interessadas na mesma viagem com empresas de transporte executivo especializadas, possibilitando viagens de ônibus mais confortáveis e seguras com economias de até 60%.',
        url: 'https://www.buser.com.br/',
        sameAs: [
          'https://facebook.com/buser.com.br',
          'https://instagram.com/buser/',
          'https://twitter.com/buserbrasil',
          'https://youtube.com/channel/UCLYHRLCxtVoLyObIHh9IboQ',
          'https://linkedin.com/company/buserbrasil/',
          'https://play.google.com/store/apps/details?id=br.com.buser.AppBuser',
          'https://apps.apple.com/br/app/buser/id1383778218'
        ],
        inLanguage: [
          {
            '@type': 'Language',
            name: 'Português brasileiro',
            alternateName: 'pt-BR'
          },
          {
            '@type': 'Language',
            name: 'Portuguese',
            alternateName: 'PT'
          }
        ],
        dateCreated: '2017-06-02',
        copyrightYear: '2017',
        funder: [
          'SoftBank',
          'Grupo Globo',
          'Canary',
          'Yellow Ventures',
          'Fundação Estudar Alumni Partners'
        ]
      }
    ]
    return {
      __dangerouslyDisableSanitizers: ['script'],
      script: [
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(structuredData)
        }
      ]
    }
  },
  data () {
    return {
      showBanner: false
    }
  },
  watch: {
    '$route' () {
      if (process.browser) {
        window.scrollTo(0, 0)
      }
    }
  },
  computed: {
    options () {
      return this.$route.meta
    },
    loggedIn () {
      return this.$store && this.$store.getters['auth/loggedIn']
    },
    publicPath () {
      return process.env.NUXT_PUBLIC_PATH || ''
    }
  }
}
</script>
