import cookiehelper from './cookiehelper'
let ctx = null

function generateHash () {
  return [...Array(16)].map(i => (~~(Math.random() * 36)).toString(36)).join('')
}

export default {
  init (_ctx) {
    ctx = _ctx
    const loggedIn = ctx.store.getters['auth/loggedIn']
    const anonUserId = cookiehelper.getItem('anonUserId')
    if (!loggedIn && !anonUserId) {
      const anonUserId = generateHash()
      cookiehelper.setItem('anonUserId', anonUserId)
    } else if (loggedIn && anonUserId) {
      cookiehelper.removeItem('anonUserId')
    }
  }
}
