export default ({ store, redirect }) => {
  if (!store.getters['auth/loggedIn']) {
    return redirect('/perfil/entrar')
  }
  const pode_entrar = store.state.auth.currentUser.permissions.REVENDEDOR || store.state.auth.currentUser.permissions.STAFF
  if (!pode_entrar) {
    store.dispatch('auth/logout').then(() =>
      redirect('/perfil/entrar')
    )
  }
}
