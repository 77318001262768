<template>
  <router-link
    :to="to"
    :class="{'height100': height100, 'button': button}">
    <slot></slot>
  </router-link>
</template>

<script>
import { compile } from 'path-to-regexp'
import sentryhelper from '~/helpers/sentryhelper'

export default {
  props: {
    to: {
      type: Object,
      default: () => ({})
    },
    height100: {
      type: Boolean,
      default: false
    },
    button: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    resolvePath (path) {
      // Substitui os parâmetros do path, como :origem, :slug? (opcional).
      const pathCompiler = compile(path)
      try {
        return pathCompiler(this.to.params)
      } catch (e) {
        sentryhelper.captureException(e)
        return '/'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.height100 {
  height: 100%;
}
.button {
  color: white;
  background: #d81b60;
  width: 100%;
  border-radius: 5px;
  font-weight: 500;
  padding: 5px 0px;
}
</style>
