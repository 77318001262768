const middleware = {}

middleware['anonymous'] = require('../middleware/anonymous.js')
middleware['anonymous'] = middleware['anonymous'].default || middleware['anonymous']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['complete-registration'] = require('../middleware/complete-registration.js')
middleware['complete-registration'] = middleware['complete-registration'].default || middleware['complete-registration']

middleware['confirm-phone-mw'] = require('../middleware/confirm-phone-mw.js')
middleware['confirm-phone-mw'] = middleware['confirm-phone-mw'].default || middleware['confirm-phone-mw']

middleware['envs'] = require('../middleware/envs.js')
middleware['envs'] = middleware['envs'].default || middleware['envs']

middleware['invite_cookie'] = require('../middleware/invite_cookie.js')
middleware['invite_cookie'] = middleware['invite_cookie'].default || middleware['invite_cookie']

middleware['is-revendedor'] = require('../middleware/is-revendedor.js')
middleware['is-revendedor'] = middleware['is-revendedor'].default || middleware['is-revendedor']

middleware['logged-user'] = require('../middleware/logged-user.js')
middleware['logged-user'] = middleware['logged-user'].default || middleware['logged-user']

middleware['ponto-de-venda'] = require('../middleware/ponto-de-venda.js')
middleware['ponto-de-venda'] = middleware['ponto-de-venda'].default || middleware['ponto-de-venda']

middleware['settings'] = require('../middleware/settings.js')
middleware['settings'] = middleware['settings'].default || middleware['settings']

middleware['slug-compat'] = require('../middleware/slug-compat.js')
middleware['slug-compat'] = middleware['slug-compat'].default || middleware['slug-compat']

middleware['unlogged-user'] = require('../middleware/unlogged-user.js')
middleware['unlogged-user'] = middleware['unlogged-user'].default || middleware['unlogged-user']

export default middleware
