export const state = () => ({
  opened: false,
  topic: null
})

export const mutations = {
  open (state) {
    state.opened = true
  },
  close (state) {
    state.opened = false
    state.topic = null
  },
  setTopic (state, topic) {
    state.topic = topic
  }
}
