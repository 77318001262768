import VueRouter from 'vue-router'
import { routesVuetify } from './../routes.js'
import { pathToRegexp, compile } from 'path-to-regexp'
import sentryhelper from '~/helpers/sentryhelper'

export default {
  push (routeNameOrPath, currentRouteName, params, query, router) {
    const FORCE_ANCHOR_ROUTES = ['home']
    const namedRoute = this.getRoute(routeNameOrPath)
    const isToForceAnchor = FORCE_ANCHOR_ROUTES.includes(routeNameOrPath)

    // Caso não tenha encontrado a rota pelo nome, procura pelo path
    // Path apenas deve ser enviado pelo nextRoute em redirects, caso contrário, deve-se enviar o 'name'
    if (!namedRoute) {
      const isRouteValid = this._isRouteValid(routeNameOrPath, routesVuetify)
      if (!isRouteValid) {
        return
      }
      this._redirect(routeNameOrPath, 'path', currentRouteName, router, { acceptWindowLocation: true })
      return
    }

    // Caso seja para forçar o window.location nem cai aqui
    if (!isToForceAnchor) {
      const opts = { acceptWindowLocation: false, params, query }
      const redirected = this._redirect(routeNameOrPath, 'name', currentRouteName, router, opts)
      if (redirected) {
        return
      }
    }
    window.location.href = this.compileRoutePath(namedRoute.path, params, query)
  },
  getRoute (routeName) {
    return routesVuetify.find(route => route.name === routeName)
  },
  compileRoutePath (nextRoutePath, currentRouteParams, currentRouteQuery) {
    // Substitui os parâmetros no path
    const pathCompiler = compile(nextRoutePath)
    let path = null
    try {
      path = pathCompiler(currentRouteParams)
    } catch (e) {
      sentryhelper.captureException(e)
      path = '/'
    }

    // Adiciona os query params se existirem
    if (currentRouteQuery && Object.keys(currentRouteQuery).length > 0) {
      const queries = []
      for (const i in currentRouteQuery) {
        queries.push(`${i}=${currentRouteQuery[i]}`)
      }

      path += `?${queries.join('&')}`
    }

    return path
  },
  _redirect (route, type, currentRoute, router, opts) {
    const routeExists = this.getRoute(currentRoute)

    if (routeExists) {
      const pushTo = {}
      pushTo[type] = route
      if (opts && opts.params) {
        pushTo.params = opts.params
      }
      if (opts && opts.query) {
        pushTo.query = opts.query
      }
      router.push(pushTo).catch((err) => {
        if (VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.duplicated)) {
          return
        }
        throw err
      })
      return true
    }
    if (opts && opts.acceptWindowLocation) {
      window.location.href = route
    }
  },
  _isRouteValid (route, routeList) {
    return routeList.some(routeObj => {
      const re = pathToRegexp(routeObj.path)
      return re.test(route)
    })
  }
}
