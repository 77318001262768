import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify, {
  theme: {
    primary: '#D81B60',
    secondary: '#D81B60',
    accent: '#D81B60',
    error: '#D32F2F',
    info: '#1976D2',
    success: '#388E3C',
    warning: '#FFA000'
  },
  treeShake: true
})
