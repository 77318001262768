import { $axios } from '~/plugins/axios'
import FormData from 'form-data'

const vendasHeader = { Client: 'vendas' }

export function get (url, params) {
  return $axios.get(url, { params: params, headers: vendasHeader })
}

export function post (url, params, config = {}) {
  const fd = new FormData()
  params = params || {}
  Object.keys(params).forEach((k) => {
    fd.append(k, params[k])
  })
  const headers = config.headers || {}
  config.headers = { ...headers, ...vendasHeader }
  return $axios.post(url, fd, config)
}
