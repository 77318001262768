
import { get, post } from './ajaxutils'

export default {
  listCidades (params = {}) {
    params = {
      onlyTrechosAtivos: params.onlyTrechosAtivos,
      onlyDestinos: params.onlyDestinos
    }
    return get('/api/cidades', params).then(response => response.data)
  },
  pontosAtivosNaCidade (cidade) {
    const params = {
      cidade
    }
    return get('/api/pontos_ativos', params).then(response => response.data)
  },
  enviarCampanhaArtesp (name) {
    return post('/api/campanha_artesp/sign', { name }).then(response => response.data)
  },
  countCampanhaArtesp () {
    return get('/api/campanha_artesp/count').then(response => response.data)
  },
  saveLead (data = {}) {
    return post('/api/leads/save', { params: JSON.stringify(data) }).then(response => response.data)
  },
  settings () {
    return get('/api/settings').then(response => response.data)
  },
  getStatusVerificarCadastro () {
    return get('/api/verifyaccount/status').then(response => response.data)
  },
  validarVerificacaoCadastro (data) {
    return post('/api/validateverifyaccount', { data: JSON.stringify(data) }).then(response => response.data)
  },
  verificarCadastro (data, config) {
    return post('/api/verifyaccount', { data: JSON.stringify(data) }, config).then(response => response.data)
  },
  getHelpQuestions () {
    return get('/api/get_helpquestions').then(response => response.data)
  },
  getTexto (slug) {
    return post(`/api/textos/${slug}`).then(response => response.data)
  },
  dapau () {
    return get('/api/dapau').then(response => response.data)
  },
  saveUserAcquisitionData (utm_params, referer) {
    return post('/api/save_user_acquisition_data', { utm_params: JSON.stringify(utm_params), referer }).then(response => response.data)
  },
  listEmpresasParceiras () {
    return get('/api/empresas_parceiras').then(response => response.data)
  },
  getEmpresa (slug) {
    return get(`/api/empresas_parceiras/${slug}`).then(response => response.data)
  },
  getTermos (params) {
    return get('/api/glossario/termos', params).then(response => response.data)
  },
  locaisFavoritos () {
    return get('/api/search/locais-favoritos').then(response => response.data)
  },
  getCategorias (categoriaSlug) {
    return get('/api/glossario/categorias', { slug: categoriaSlug }).then(response => response.data)
  },
  getCategoriasInfo (categoriaSlugs) {
    return get('/api/glossario/categorias/info', { slugs: JSON.stringify(categoriaSlugs) }).then(response => response.data)
  },
  getTermoBySlug (slug) {
    return get('/api/glossario/termos/termo', { slug }).then(response => response.data)
  },
  getItemsByTag (slug) {
    return get('/api/glossario/tags/items', { slug }).then(response => response.data)
  },
  getTagBySlug (slug) {
    return get('/api/glossario/tags/tag', { slug }).then(response => response.data)
  },
  listNews ({ limit } = {}) {
    return get('/api/blog/news', { limit }).then(response => response.data)
  },
  listArticles ({ limit } = {}) {
    return get('/api/blog/articles', { limit }).then(response => response.data)
  },
  getCategoriasEspeciais (trecho_classe_id) {
    return get('/api/integracao_rodoviaria/get-vagas-por-categoria-especial', { trecho_classe_id }).then(response => response.data)
  }
}
