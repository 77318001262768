import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjsPluginUTC from 'dayjs-plugin-utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(customParseFormat)
dayjs.extend(dayjsPluginUTC)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
