import axios from 'axios'
import axiosRetry from 'axios-retry'
import handler from '~/helpers/ajaxhandler'

axios.defaults.headers.common.tabid = (Math.random() * 1e8).toFixed(0)

const $axios = axios.create({
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken',
  withCredentials: true
})

axiosRetry($axios, { retryDelay: axiosRetry.exponentialDelay })

export { $axios }

export default ({ store }) => {
  $axios.defaults.baseURL = process.env.BASE_URL

  if (process.client) {
    $axios.interceptors.response.use(
      handler.onPromiseResolved,
      error => handler.onPromiseRejected(error, store)
    )

    window.addEventListener('unhandledrejection', event => {
      handler.onUnhandledRejection(event.reason)
      event.preventDefault()
    })
  } else {
    const interceptor = $axios.interceptors.response.use(
      async response => {
        try {
          return await handler.onPromiseResolved(response)
        } finally {
          $axios.interceptors.response.eject(interceptor)
        }
      },
      async error => {
        try {
          return await handler.onPromiseRejected(error)
        } finally {
          $axios.interceptors.response.eject(interceptor)
        }
      }
    )
  }
}
