export const state = () => ({
  showForm: false,
  showSmsSent: false,
  timeout: 60,
  countdownStarted: false,
  error: null,
  redirectURL: null
})

export const mutations = {
  show (state, redirectURL = null) {
    state.error = null
    state.showSmsSent = false
    state.showForm = true
    state.redirectURL = redirectURL
  },
  showSmsSent (state) {
    state.error = null
    state.showForm = false
    state.showSmsSent = true
  },
  showError (state, error) {
    state.error = error
    state.showForm = false
    state.showSmsSent = false
  },
  hide (state) {
    state.error = null
    state.showForm = false
    state.showSmsSent = false
    state.redirectURL = null
  },
  setSmsTimeout (state, timeout) {
    state.timeout = timeout
  },
  decreaseSmsTimeout (state) {
    if (state.timeout > 0) {
      state.timeout--
    }
  },
  setCountDownStarted (state, val) {
    state.countdownStarted = val
  }
}

export const getters = {
  visible (state) {
    return state.showForm || state.showSmsSent || !!state.error
  },
  isSmsFormVisible (state) {
    return !state.error && state.showForm
  },
  isSmsSentVisible (state) {
    return !state.error && state.showSmsSent
  },
  isErrorVisible (state) {
    return !!state.error
  },
  getError (state) {
    return state.error
  },
  smsTimeout (state) {
    return state.timeout
  },
  redirectURL (state) {
    return state.redirectURL
  }
}

export const actions = {
  restartTimer ({ commit, dispatch }, defaultTimeout = 60) {
    commit('setCountDownStarted', false)
    commit('setSmsTimeout', defaultTimeout)
    dispatch('initTimer')
  },
  initTimer ({ state, commit, dispatch }) {
    if (state.countdownStarted) return
    commit('setCountDownStarted', true)
    dispatch('countdownTimer')
  },
  countdownTimer ({ state, dispatch, commit }) {
    setTimeout(() => {
      commit('decreaseSmsTimeout')
      if (state.timeout > 0) {
        dispatch('countdownTimer')
      }
    }, 1000)
  }
}

export const confirmacaoCelularModule = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
