import searchbox from '~/helpers/searchbox'

export const state = () => ({
  placeBySlug: {},
  result: []
})

export const mutations = {
  setResult (state, items) {
    state.result = items
  },
  cachePlaces (state, places) {
    places.slice(0, 10).forEach(place => {
      state.placeBySlug[place.slug] = place
    })
  },
  clearSearch (state) {
    state.result = []
  }
}

export const actions = {
  async search ({ commit }, param) {
    const res = await searchbox.search(param)
    await commit('setResult', res)
    commit('cachePlaces', res)
    return res
  },
  clearSearch ({ commit }) {
    commit('clearSearch')
  },
  getCachedSlug ({ state }, slug) {
    return state.placeBySlug[slug]
  }
}
