import hibothelper from '~/helpers/hibothelper'

function openHiChatDesktop (user) {
  hibothelper.initChat(user)
}

export default {
  openHiChat (store) {
    const user = store.getters['session/user']
    openHiChatDesktop(user)
  }
}
