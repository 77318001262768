export const state = () => ({
  opened: false,
  timeout: 6000,
  animationDuration: 500,
  timeoutFn: null
})

export const mutations = {
  open (state, data) {
    state.opened = true
    state.timeout = data.timeout || state.timeout
    state.animationDuration = data.animationDuration || state.animationDuration

    clearTimeout(state.timeoutFn)

    state.timeoutFn = setTimeout(() => {
      this.commit('tooltip-promo/close')
    }, state.timeout)
  },
  close (state) {
    state.opened = false
  }
}

export const getters = {
  opened (state) {
    return state.opened
  }
}

export const tooltipPromoModule = {
  namespaced: true,
  state,
  mutations,
  getters
}
