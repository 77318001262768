import api from '~api'

export const state = () => ({
  questions: null
})

export const mutations = {
  set (state, questions) {
    state.questions = questions
  }
}

export const getters = {
  get (state) {
    return state.questions
  }
}

export const actions = {
  async init ({ commit, state }) {
    if (state.questions === null) {
      const questions = await api.everyone.getHelpQuestions()
      commit('set', questions)
    }
  }
}
