import api from '~api'
import EventBus from '~/helpers/eventbus'
import { setTrechoSign } from '~/helpers/signedtrecho.js'

export default {
  fetchIfDirty (data, route, $store) {
    const step = parseInt(route.query.step || 0)
    const dirty = data.searchStep !== step ||
      data.origemSlug !== route.params.origem ||
      data.destinoSlug !== route.params.destino ||
      data.departureDate !== route.query.ida ||
      data.returnDate !== (route.query.volta || '')
    if (data.groupsByDate && !dirty) {
      return data
    }
    data.searchStep = step
    data.origemSlug = route.params.origem
    data.destinoSlug = route.params.destino
    data.departureDate = route.query.ida
    data.returnDate = route.query.volta || ''
    if (step === 0) {
      $store.commit('search/init', data)
      $store.commit('search/setBuscaIdaEVolta', route.query.ida && route.query.volta)
    }
    data.loading = true
    return goSearch()
    function goSearch () {
      const buscandoVolta = step === 1 && data.returnDate && data.grupoIda
      const searchParams = {
        origemSlug: buscandoVolta ? data.destinoSlug : data.origemSlug,
        destinoSlug: buscandoVolta ? data.origemSlug : data.destinoSlug
      }
      if (buscandoVolta) {
        searchParams.minClasse = data.grupoIda.tipo_assento
      }
      if (data.returnDate || data.departureDate) {
        searchParams.departureDate = buscandoVolta ? data.returnDate : data.departureDate
      }
      if (!searchParams.origemSlug || !searchParams.destinoSlug) {
        return trechosSugeridos(searchParams).finally(() => {
          data.loading = false
        })
      }
      searchParams.includesParada = data.includesParada
      return api.passenger.search(searchParams).then(buscandoVolta ? receiveResultVolta : receiveResultIda).then(() => {
        // checa se é um trecho que não vendemos para poder mostrar alternativas
        if (!data.results.is_trecho_vendido) {
          return trechosSugeridos({ ...searchParams, limit: 3 })
        }
        // vendemos mas não existem grupos disponíveis na data
        if (!data.results.has_grupos) {
          data.showSearch = true
        }
        return data
      }).finally(() => {
        data.loading = false
      })
    }
    function trechosSugeridos (searchParams) {
      // busca por rotas sugeridas
      const p1 = searchParams.origemSlug ? api.passenger.searchOrigem(searchParams) : Promise.resolve()
      const p2 = searchParams.destinoSlug ? api.passenger.searchDestino(searchParams) : Promise.resolve()
      return Promise.all([p1, p2]).then(([rotas_saindo_de, rotas_indo_para]) => {
        data.results = {
          rotas_saindo_de,
          rotas_indo_para
        }
        data.showSearch = true
        return data
      })
    }
    function receiveResultIda (results) {
      if (!results) {
        return { go: 'home' }
      }
      data.results = results
      return data
    }
    function receiveResultVolta (results) {
      if (!results) {
        return { go: 'home' }
      }
      const grupoIda = $store.getters['search/grupoIda']
      results.groups_by_date = results.groups_by_date.map(groupSet => {
        return {
          datetime_ida: groupSet.datetime_ida,
          grupos: groupSet.grupos.filter(grupo => {
            return grupo.datetime_ida > grupoIda.datetime_ida
          })
        }
      })
      data.results = results
      return data
    }
  },
  next (group, $store, $router, $route, $cookies) {
    $store.commit('search/next', group)
    const mostraLabelBoomerangIdaGratis = $store.getters['travels/shouldShowFreeLabelFirstTravel']
    const buscaPromocao = $store.getters['search/buscaPromocao']
    const completed = $store.getters['search/completed']
    const { grupoIda, grupoVolta } = $store.state.search

    if (completed) {
      setTrechoSign(grupoIda.id, grupoIda.signed, 40 * 60)
      if (grupoVolta) {
        setTrechoSign(grupoVolta.id, grupoVolta.signed, 40 * 60)
      }
    }

    $store.dispatch('search/selectTabDate', $route.query.volta || group.chegada_ida.split('T')[0])
    EventBus.$emit('click-view-group', { ...(grupoVolta || grupoIda), query: $route.query, is_volta: !!grupoVolta })
    if (completed && (buscaPromocao || !mostraLabelBoomerangIdaGratis)) {
      const idIda = grupoIda.id
      const idVolta = grupoVolta ? grupoVolta.id : null
      push($store, $router, { name: 'group', params: { idIda, idVolta } })
    } else {
      const route = {
        name: 'searchPage',
        params: $route.params,
        query: {
          ida: $route.query.ida || group.datetime_ida.split('T')[0],
          volta: $route.query.volta || group.chegada_ida.split('T')[0],
          step: 1
        }
      }
      push($store, $router, route)
    }
  },
  prev ($store, $router, $route) {
    const route = {
      name: 'searchPage',
      params: $route.params,
      query: {
        ida: $route.query.ida,
        volta: $route.query.volta,
        step: 0
      }
    }

    $store.dispatch('search/selectTabDate', $route.query.ida)

    push($store, $router, route)
  },
  reservarSoIda ($router, $store) {
    const grupoIda = $store.state.search.grupoIda
    if (!grupoIda) {
      return
    }
    const idIda = grupoIda.id
    EventBus.$emit('click-view-group', grupoIda)
    push($store, $router, { name: 'group', params: { idIda } })
  },

  buildCityItems (allCities, trechosVendidos, fromCity = null, userData = null) {
    const cidadesUser = {}
    const cidadesVendidas = []
    const cidadesNaoVendidas = []

    allCities.forEach(city => {
      if (fromCity && city.id === fromCity.id) {
        return
      }

      if (trechosVendidos[city.id]) {
        if (userData && userData.cidades.includes(city.id)) {
          cidadesUser[city.id] = city
        } else {
          cidadesVendidas.push({ ...city, ativo: true })
        }
      } else {
        cidadesNaoVendidas.push({ ...city, ativo: false })
      }
    })

    if (Object.keys(cidadesUser).length !== 0) {
      for (let i = userData.cidades.length - 1; i >= 0; i--) {
        const city = cidadesUser[userData.cidades[i]]

        if (city) {
          cidadesVendidas.unshift({ ...city, ativo: true })
        }
      }
    }

    return cidadesVendidas.concat(cidadesNaoVendidas).map(
      city => ({ ...city, text: `${city.name} - ${city.uf}` })
    )
  },

  buildTrechosVendidosMap (trechosVendidos) {
    const map = {}

    trechosVendidos.forEach(tv => {
      const [de, ate] = tv

      if (!map[de]) {
        map[de] = {}
      }

      map[de][ate] = true
    })

    return map
  },
  parsePromoSearchResponse (data) {
    data.forEach(d => {
      const locais = d.locais

      d.itinerario = d.itinerario.map(iti => {
        return { ...iti, local: locais[iti.local_id] }
      })
    })

    return data
  },

  parseSearchResponse (response) {
    response.groups_by_date.forEach(item => (
      item.grupos.forEach(grupo => {
        const extra = {
          origem: response.locais[grupo.origem_id],
          destino: response.locais[grupo.destino_id],
          itinerario: response.itinerarios[grupo.rota_id],
          parada_lanche: response.parada_lanche
        }
        Object.assign(grupo, extra)
      })
    ))

    Object.values(response.itinerarios).forEach(iti => {
      iti.forEach(checkpoint => {
        checkpoint.local = response.locais[checkpoint.local_id]
      })
    })

    delete response.locais
    delete response.itinerarios

    return response
  },

  parseSearchResponseVuetify (response) {
    let count = 0
    response.groups_by_date.forEach(item => {
      const grupos = []
      item.grupos.forEach(grupo => {
        if (!grupo.trecho_alternativo) {
          const extra = {
            origem: response.locais[grupo.origem_id],
            destino: response.locais[grupo.destino_id],
            itinerario: response.itinerarios[grupo.rota_id]
          }
          Object.assign(grupo, extra)
          grupos.push(grupo)
          count += 1
        }
      })
      item.grupos = grupos
    })

    Object.values(response.itinerarios).forEach(iti => {
      iti.forEach(checkpoint => {
        checkpoint.local = response.locais[checkpoint.local_id]
      })
    })

    response.has_grupos = count > 0
    delete response.locais
    delete response.itinerarios

    return response
  }
}

const push = ($store, $router, route) => {
  $store.commit('search/setLoading', true)
  $router.push(route, () => {
    $store.commit('search/setLoading', false)
  })
}

export function filtraGruposConfirmados (grupos, fnOrdenacao = null) {
  if (!grupos) {
    return []
  }
  const confirmados = grupos.filter(g => !(g.status === 'pending' && g.confirming_probability === 'very_low'))
  return fnOrdenacao ? confirmados.sort(fnOrdenacao) : confirmados
}

export function filtraGruposAConfirmar (grupos, fnOrdenacao = false) {
  if (!grupos) {
    return []
  }
  const aConfirmar = grupos.filter(g => g.status === 'pending' && g.confirming_probability === 'very_low')
  return fnOrdenacao ? aConfirmar.sort(fnOrdenacao) : aConfirmar
}

export function partitionByTipoTrecho (groupsByDate) {
  const trechoPesquisado = { confirmados: [], aConfirmar: [] }
  const trechoAlternativo = { confirmados: [], aConfirmar: [] }

  groupsByDate.forEach(item => {
    const confirmadosTrechoPesquisado = []
    const confirmadosTrechoAlternativo = []
    const aConfirmarTrechoPesquisado = []
    const aConfirmarTrechoAlternativo = []

    item.grupos.forEach(group => {
      if (grupoAConfirmar(group)) {
        group.trecho_alternativo ? aConfirmarTrechoAlternativo.push(group) : aConfirmarTrechoPesquisado.push(group)
      } else {
        group.trecho_alternativo ? confirmadosTrechoAlternativo.push(group) : confirmadosTrechoPesquisado.push(group)
      }
    })

    trechoPesquisado.confirmados.push({ datetime_ida: item.datetime_ida, grupos: confirmadosTrechoPesquisado })
    trechoPesquisado.aConfirmar.push({ datetime_ida: item.datetime_ida, grupos: aConfirmarTrechoPesquisado })

    trechoAlternativo.confirmados.push({ datetime_ida: item.datetime_ida, grupos: confirmadosTrechoAlternativo })
    trechoAlternativo.aConfirmar.push({ datetime_ida: item.datetime_ida, grupos: aConfirmarTrechoAlternativo })
  })

  return [trechoPesquisado, trechoAlternativo]
}

const grupoAConfirmar = group => group.status === 'pending' && group.confirming_probability === 'very_low'

export function filterGruposTrechoPesquisado (results) {
  let count = 0
  const groupsByDate = []

  results.groups_by_date.forEach(item => {
    const grupos = item.grupos.filter(g => !g.trecho_alternativo)
    count += grupos.length
    groupsByDate.push({ ...item, grupos })
  })

  return { ...results, groups_by_date: groupsByDate, has_grupos: count > 0 }
}
