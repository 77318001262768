// https://stackoverflow.com/questions/52452501/how-to-add-a-polyfill-to-nuxt-2-0
import 'core-js/fn/object/entries'
import 'core-js/fn/object/assign'
import 'core-js/fn/object/values'
import 'core-js/fn/array/includes'
import 'core-js/fn/array/find'
import 'core-js/fn/array/from'
import 'core-js/es6/math'
import 'core-js/es6/promise'
import 'core-js/es6/symbol'
import 'core-js/es6/number'

/**
 * Se estivessemos usando core-js@3.x.x daria pra importar o replaceAll,
 * mas como o nuxt depende do core-js@2 segue o ctrl+c ctrl+v do polyfill
 *
 * Saiba mais:
 * https://github.com/nuxt/nuxt.js/issues/6579
 * https://vanillajstoolkit.com/polyfills/stringreplaceall/
 *
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */

/* eslint no-extend-native: ["error", { "exceptions": ["String"] }] */
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
      return this.replace(str, newStr)
    }

    // If a string
    return this.replace(new RegExp(str, 'g'), newStr)
  }
}
