export const state = () => ({
  travelBannerVisible: false
})

export const mutations = {
  setTravelBannerVisibility (state, status) {
    state.travelBannerVisible = status
  }
}

export const getters = {
  travelBanner (state) {
    return state.travelBannerVisible
  }
}
