export const state = () => ({
  opened: false,
  message: ''
})

export const mutations = {
  open (state, msg) {
    state.opened = true
    state.message = msg
  },
  close (state) {
    state.opened = false
    state.message = ''
  }
}
