export const state = () => ({
  currentLead: undefined,
  closedPopup: false
})

export const mutations = {
  updateCurrentLead (state, props) {
    if (!state.currentLead) {
      state.currentLead = {}
    }
    Object.assign(state.currentLead, props)
  },
  removeCurrentLead (state) {
    state.currentLead = undefined
  },
  closePopup (state) {
    state.closedPopup = true
  }
}

export const getters = {
  email (state) {
    return state.currentLead.email
  },
  phone (state) {
    return state.currentLead.phone
  },
  existsLead (state) {
    return !!state.currentLead
  }
}
