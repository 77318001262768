import dayjs from 'dayjs'
import Vue from 'vue'
import { CPF, CNPJ } from 'cpf_cnpj'

function dayjs_notz (value) {
  if (typeof value === 'string' && value.match(/.*[+-]\d\d:\d\d$/g)) {
    value = value.substring(0, value.length - 6)
  }
  return dayjs(value)
}

Vue.filter('fromnow', value => {
  if (!value) return
  return dayjs_notz(value).fromNow(true)
})

Vue.filter('daymonth', value => {
  if (!value) return
  return dayjs_notz(value).format('DD MMM')
})

Vue.filter('daybarmonth', value => {
  if (!value) return
  return dayjs_notz(value).format('DD/MM')
})

Vue.filter('shortdaymonthhour', value => {
  if (!value) return
  return dayjs_notz(value).format('DD/MM HH:mm')
})

Vue.filter('daymonthhour', value => {
  // Ex: 23/03 às 22:00
  if (!value) return
  return dayjs_notz(value).format('DD/MM [às] HH:mm')
})

Vue.filter('day', value => {
  if (!value) return
  return dayjs_notz(value).format('DD')
})

Vue.filter('weekday', value => {
  if (!value) return
  return dayjs_notz(value).format('ddd')
})

Vue.filter('month', value => {
  if (!value) return
  return dayjs_notz(value).format('MMM')
})

Vue.filter('date', value => {
  if (!value) return
  return dayjs_notz(value).format('DD/MM/YYYY')
})

Vue.filter('dateshortyear', value => {
  if (!value) return
  return dayjs_notz(value).format('DD/MM/YY')
})

Vue.filter('datetime', value => {
  if (!value) return
  return dayjs_notz(value).format('DD/MM/YYYY [às] HH:mm')
})

Vue.filter('datetimeshortyear', value => {
  if (!value) return
  return dayjs_notz(value).format('DD/MM/YY [às] HH:mm')
})

Vue.filter('hour', value => {
  if (!value) return
  return dayjs_notz(value).format('HH:mm')
})

Vue.filter('extendeddaymonthyear', value => {
  if (!value) return
  return dayjs_notz(value).format('DD [de] MMMM [de] YYYY')
})

Vue.filter('extendedweekdaydaymonthyear', value => {
  if (!value) return
  return dayjs_notz(value).format('dddd, DD [de] MMMM [de] YYYY')
})

Vue.filter('interval', value => {
  if (!value) return
  let duracao_horas = value / 1000.0 / 60 / 60
  const duracao_minutos = Math.round((duracao_horas % 1) * 60)
  duracao_horas = parseInt(duracao_horas)
  return `${duracao_horas >= 10 ? '' : '0'}${duracao_horas}:${duracao_minutos >= 10 ? '' : '0'}${duracao_minutos}`
})

Vue.filter('hour', value => {
  if (!value) return
  return dayjs_notz(value).format('HH:mm')
})

Vue.filter('timer', value => {
  let seconds = value
  const hours = parseInt(seconds / 3600)
  seconds = seconds % 3600
  const minutes = parseInt(seconds / 60)
  seconds = seconds % 60

  const format = hours > 0 ? 'HH:mm:ss' : 'mm:ss'
  const timeValue = dayjs().hour(hours).minute(minutes).second(seconds)
  return dayjs_notz(timeValue).format(format)
})

Vue.filter('dbdate', value => {
  if (!value) return
  return dayjs_notz(value).format('YYYY-MM-DD')
})

Vue.filter('dbdatetime', value => {
  if (!value) return
  return dayjs_notz(value).format('YYYY-MM-DD_HH-mm')
})

Vue.filter('extendeddate', value => {
  if (!value) return
  return dayjs_notz(value).format('ddd, DD/MMM')
})

Vue.filter('extendeddatehour', value => {
  // Ex: Sáb, 23/03, 22:00
  if (!value) return
  return dayjs_notz(value).format('ddd, DD/MM, HH:mm')
})

Vue.filter('extendedweekday', value => {
  if (!value) return
  return dayjs_notz(value).format('dddd, DD MMM')
})

Vue.filter('extendeddayfullmonth', (value, short) => {
  if (!value) return
  const formattedDate = dayjs_notz(value).format('dddd, DD [de] MMMM')

  if (!['day', 'month', 'daymonth'].includes(short)) {
    return formattedDate
  }

  const splittedDate = formattedDate.replace(',', '').split(' ')
  const shortDay = splittedDate[0].slice(0, 3)
  const shortMonth = splittedDate[3].slice(0, 3)

  if (short === 'day') {
    return `${shortDay}, ${splittedDate[1]} ${splittedDate[2]} ${splittedDate[3]}.`
  }
  if (short === 'month') {
    return `${splittedDate[0]}, ${splittedDate[1]} ${splittedDate[2]} ${shortMonth}.`
  }
  if (short === 'daymonth') {
    return `${shortDay}, ${splittedDate[1]} ${splittedDate[2]} ${shortMonth}.`
  }
})

Vue.filter('weekdayhour', value => {
  // Ex: Sáb 23 mar, 22:00
  if (!value) return
  return dayjs_notz(value).format('ddd DD MMM, HH:mm')
})

Vue.filter('extendedweekdayhour', value => {
  if (!value) return
  return dayjs_notz(value).format('dddd, DD MMM, HH:mm')
})

Vue.filter('fromnow', value => {
  if (!value) return
  return dayjs_notz(value).fromNow()
})

Vue.filter('number', (value, decimals) => {
  if (value === null || value === undefined || isNaN(value)) return value

  const values = Number(value).toFixed(decimals).split('.')
  return [values[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'), values[1]].filter(Boolean).join()
})

Vue.filter('pluralize', (value, singular, plural, omitNumber) => {
  if (value === null || value === undefined) {
    return null
  } else if (value === 1 && omitNumber) {
    return `${singular}`
  } else if (value === 1) {
    return `1 ${singular}`
  } else if ((value > 1 || value === 0) && omitNumber) {
    return `${plural}`
  } else {
    return `${value} ${plural}`
  }
})

Vue.filter('trim', (value, countChars) => {
  if (value && value.length >= countChars + 2) {
    return `${value.slice(0, countChars)}...`
  }
  return value
})

Vue.filter('cnpj', value => {
  if (!value) return
  return CNPJ.format(value)
})

Vue.filter('cpf', value => {
  if (!value) return
  return CPF.format(value)
})

Vue.filter('phone', value => {
  if (!value) return
  return value.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2 $3')
})

Vue.filter('birthday', value => {
  if (!value) return
  return value.toString().replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3')
})

Vue.filter('cidade', cidade => {
  if (!cidade) return

  if (cidade.label) {
    return cidade.label
  } else if (cidade.uf && cidade.name) {
    return `${cidade.name} - ${cidade.uf}`
  }
})

Vue.filter('trechosigla', trecho => {
  if (!trecho) return
  return `${trecho.origem.sigla} → ${trecho.destino.sigla}`
})

Vue.filter('trechoname', trecho => {
  if (!trecho) return
  return `${trecho.origem.name}-${trecho.origem.uf} → ${trecho.destino.name}-${trecho.destino.uf}`
})

Vue.filter('parcela', p => {
  const filterReal = Vue.options.filters.real
  let description = `${p.parcela_count}x de ${filterReal(p.parcela_value)}`
  if (p.total_parcelado !== p.valor_pagamento && p.total_parcelado !== p.pagamento_total) {
    description += ` (${filterReal(p.total_parcelado)})`
  }
  return description
})

Vue.filter('real', (value, signed = false) => {
  if (value === undefined || value === null) return
  const signal = value < 0 ? '- ' : (signed && Math.abs(value) >= 0.01 ? '+ ' : '')
  const currency = 'R$'
  const values = Number(Math.abs(value)).toFixed(2).split('.')
  const currencyValue = [values[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'), values[1]].filter(Boolean).join()
  return `${signal}${currency} ${currencyValue}`
})

Vue.filter('bueda', (value, signed = false) => {
  if (value === undefined || value === null) return
  const signal = value < 0 ? '- ' : (signed && Math.abs(value) >= 0.01 ? '+ ' : '')
  const currency = 'c$'
  const values = Number(Math.abs(value)).toFixed(2).split('.')
  const currencyValue = [values[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'), values[1]].filter(Boolean).join()
  return `${signal}${currency} ${currencyValue}`
})

Vue.filter('percent', value => {
  if (value === undefined || value === null) return
  return `${100 * value}%`
})

Vue.filter('shortenstring', (value, maxStringLen) => {
  return value.length <= maxStringLen ? value : value.slice(0, maxStringLen) + '...'
})

Vue.filter('uppercase', (value) => {
  if (value === undefined || value === null) return
  return value.toUpperCase()
})

Vue.filter('lowercase', (value) => {
  if (value === undefined || value === null) return
  return value.toLowerCase()
})

Vue.filter('capitalize', (value) => {
  if (value === undefined || value === null) return
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('hidemost', (value, split_symbol) => {
  if (value === undefined || value === null) return
  value = value.toString()
  if (split_symbol) {
    const value_array = value.split(split_symbol)
    return value_array.map(value => value.slice(0, 2) + '*'.repeat(Math.max(value.length - 3, 0)) + value.slice(-1)).join(split_symbol)
  }
  return value.slice(0, 2) + '*'.repeat(Math.max(value.length - 3, 0)) + value.slice(-1)
})

Vue.filter('cep', (value) => {
  if (value === undefined || value === null || value.length !== 8) return
  return `${value.substring(0, 5)}-${value.substring(5, 8)}`
})
