import dayjs from 'dayjs'
import forms from '~/helpers/forms'

export const PASSENGER_DOCUMENT_TYPES = {
  PASSAPORTE_ESTRANGEIRO: 'Passaporte estrangeiro',
  RG: 'RG',
  RG_OU_CERTIDAO: 'RG ou Certidão'
}

export const PASSENGER_TYPES = {
  ESTRANGEIRO: {
    text: 'Estrangeiro',
    value: 'ESTRANGEIRO',
    document_allowed: PASSENGER_DOCUMENT_TYPES.PASSAPORTE_ESTRANGEIRO
  },
  CRIANCA: {
    text: 'Bebê ou criança menor de 16 anos',
    value: 'CRIANCA',
    document_allowed: PASSENGER_DOCUMENT_TYPES.RG_OU_CERTIDAO
  },
  ADULTO: {
    text: 'Adulto',
    value: 'ADULTO',
    document_allowed: PASSENGER_DOCUMENT_TYPES.RG
  }
}

export default {
  updatePassenger (passenger, datetime_ida) {
    passenger.name = forms.trimSpaces(passenger.name)

    // Nome social
    passenger.social_name = forms.trimSpaces(passenger.social_name)
    if (!passenger.social_name) {
      passenger.social_name = passenger.name
    }

    passenger = this.setPassengerType(passenger, datetime_ida)

    return passenger
  },
  setPassengerType (passenger, datetime_ida) {
    const datetimeIda = datetime_ida ? dayjs(datetime_ida) : dayjs()
    let birthday = dayjs(passenger.birthday, 'DDMMYYYY')
    let idade = birthday.isValid() && datetimeIda.diff(birthday, 'years')

    // Estrangeiro
    if (passenger.type === PASSENGER_TYPES.ESTRANGEIRO.value || passenger.tipo_documento === PASSENGER_DOCUMENT_TYPES.PASSAPORTE_ESTRANGEIRO) {
      passenger = setEstrangeiro(passenger)
      // Bebê de colo
      if (datetimeIda.diff(birthday, 'months') <= 18) {
        passenger.needs_cadeirinha = true
        passenger.type_text = 'Estrangeiro bebê de colo'
      } else if (idade < 16) {
        passenger.type_text = 'Estrangeiro menor de 16 anos'
      }
      return passenger
    }

    // Bebê de colo
    if (datetimeIda.diff(birthday, 'months') <= 18) {
      passenger.needs_cadeirinha = true
      passenger.type = PASSENGER_TYPES.CRIANCA.value
      passenger.type_text = 'Bebê de até 1 ano e 5 meses'
      passenger.tipo_documento = PASSENGER_TYPES.CRIANCA.document_allowed
      return passenger
    }

    // Criança
    if (idade && idade <= 16) {
      passenger.type = PASSENGER_TYPES.CRIANCA.value
      passenger.type_text = 'Menor de 16 anos'
      passenger.tipo_documento = PASSENGER_TYPES.CRIANCA.document_allowed
      return passenger
    }

    // Adulto
    passenger = setAdulto(passenger)
    birthday = null
    idade = null
    return passenger
  }
}

const setAdulto = (passenger) => {
  passenger.needs_cadeirinha = false
  passenger.tipo_documento = PASSENGER_DOCUMENT_TYPES.RG
  passenger.type_text = null
  passenger.type = PASSENGER_TYPES.ADULTO.value
  return passenger
}

const setEstrangeiro = (passenger) => {
  passenger.tipo_documento = PASSENGER_DOCUMENT_TYPES.PASSAPORTE_ESTRANGEIRO
  passenger.type = PASSENGER_TYPES.ESTRANGEIRO.value
  passenger.type_text = PASSENGER_TYPES.ESTRANGEIRO.text
  return passenger
}
