<template>
  <v-img :src="logo" :width="height * 1.2" :height="height" contain/>
</template>

<script>
import logo from '~/assets/images/buser-white.svg'

export default {
  props: {
    height: {
      type: [Number, String],
      default: 32
    }
  },
  data () {
    return {
      logo
    }
  }
}
</script>
