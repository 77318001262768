export const state = () => ({
  step: 1,
  groupSelected: null,
  alterarReserva: false,
  travel: null
})

export const mutations = {
  init (state, travel) {
    state.alterarReserva = false
    state.step = 1
    state.groupSelected = null
    state.travel = travel
  },
  increaseStep (state) {
    state.step++
  },
  setGroupSelected (state, group) {
    state.groupSelected = group
  },
  setAlterarReserva (state, alterarReserva) {
    state.alterarReserva = alterarReserva
  }
}

export const getters = {
  step (state) {
    return state.step
  },
  groupSelected (state) {
    return state.groupSelected
  },
  alterarReserva (state) {
    return state.alterarReserva
  },
  travel (state) {
    return state.travel
  }
}
