import Vue from 'vue'
import api from '~api'

const searchResultSelectedInitDict = () => {
  return { ida: [], volta: [] }
}

export const state = () => ({
  currentUser: undefined,
  searchResultsSelected: searchResultSelectedInitDict(),
  passengers: [],
  isRetencao: false,
  isVendaAtiva: false
})

export const mutations = {
  setCurrentUser (state, user) {
    state.currentUser = user

    if (user) {
      if (user.permissions === undefined) {
        user.permissions = {}
      }
    }
    state.passengers = user.cached_passengers || []
  },
  updateCurrentUser (state, props) {
    Object.assign(state.currentUser, props)
  },
  removeCurrentUser (state) {
    state.currentUser = null
  },
  clearSearchResultSelections (state) {
    state.searchResultsSelected = searchResultSelectedInitDict()
  },
  toggleSearchResult (state, payload) {
    const selecionadosDict = state.searchResultsSelected
    const selecionados = payload.isIda ? selecionadosDict.ida : selecionadosDict.volta
    const grupoIndex = selecionados.indexOf(payload.grupo)
    if (grupoIndex !== -1) {
      selecionados.splice(grupoIndex, 1)
    } else {
      selecionados.push(payload.grupo)
    }
    state.searchResultsSelected = selecionadosDict
    state.passengers = []
  },
  setPassengers (state, passengers) {
    state.passengers = passengers
  },
  setIsRetencao (state, isRetencao) {
    state.isRetencao = isRetencao
  },
  setIsVendaAtiva (state, isVendaAtiva) {
    state.isVendaAtiva = isVendaAtiva
  }
}

export const actions = {
  async fetchPassengers ({ commit, state }) {
    const data = await api.auth.passengersFromUser(state.currentUser)
    const listCpfs = getCpfs(data)
    if (listCpfs && listCpfs.length > 0) {
      const objCpfs = await api.passenger.consultaCpf(listCpfs)
      setCpfs(data, objCpfs)
    }
    return commit('setPassengers', data)
  }
}

export const getters = {
  temUserPraRevenda (state) {
    return !!(state.currentUser && state.currentUser.permissions)
  },
  userId (state) {
    return state.currentUser ? state.currentUser.id : null
  },
  currentUser (state) {
    return state.currentUser
  },
  searchResultsSelecionados (state) {
    return whatsappified(state.searchResultsSelected)
  },
  isRetencao (state) {
    return state.currentUser ? state.isRetencao : false
  },
  isVendaAtiva (state) {
    return state.currentUser ? state.isVendaAtiva : false
  },
  numeroSelecionadosIda (state) {
    return state.searchResultsSelected.ida.length
  },
  numeroSelecionadosVolta (state) {
    return state.searchResultsSelected.volta.length
  }
}

const whatsappified = (searchResultSelected) => {
  let output = ''
  if (searchResultSelected.ida.length > 0) {
    output += '👉 *Opções Ida:*\n\n'
    output += buildWhatsappCopy(searchResultSelected.ida)
  }
  if (searchResultSelected.volta.length > 0) {
    output += '\n\n\n👉 *Opções Volta:*\n\n'
    output += buildWhatsappCopy(searchResultSelected.volta)
  }
  return output
}

const buildWhatsappCopy = (listaDeGrupos) => {
  let stringzona = ''
  listaDeGrupos.forEach((grupo, index) => {
    stringzona += `*Opção ${index + 1}*\n`
    stringzona += `🕑 Partida: ${Vue.options.filters.daymonthhour(grupo.datetime_ida)}\n`
    stringzona += `🕙 Chegada (esperada): ${Vue.options.filters.daymonthhour(grupo.chegada_ida)}\n`
    stringzona += `🌆 Local de embarque: ${grupo.origem.nickname} - ${grupo.origem.endereco} - (${grupo.origem.name})\n`
    stringzona += `🌇 Local de desembarque: ${grupo.destino.nickname} - ${grupo.destino.endereco} - (${grupo.destino.name})\n`
    stringzona += `💺 Assento: ${grupo.tipo_assento}\n`
    stringzona += `💳 Preço: ${Vue.options.filters.real(grupo.max_split_value)}\n`
    stringzona += listaDeGrupos.indexOf(grupo) !== listaDeGrupos.length - 1 ? '\n---\n\n' : ''
  })
  return stringzona
}

const getCpfs = (passengers) => {
  const listCpfs = []
  for (let i = 0; i < passengers.length; i++) {
    const buseiro = passengers[i]
    const cpf = buseiro.cpf
    if (cpf) listCpfs.push(cpf)
  }
  return listCpfs
}

const setCpfs = (passengers, objCpfs) => {
  for (let i = 0; i < passengers.length; i++) {
    const buseiro = passengers[i]
    const cpf = buseiro.cpf
    if (cpf) {
      buseiro.status = objCpfs[cpf]
    }
  }
}
