import api from '~api'

function getUTMParams (routeQuery) {
  const params = Object.keys(routeQuery).filter(q => q.startsWith('utm_'))
  if (params.length === 0) return null
  const utm_params = {}
  params.forEach(p => {
    utm_params[p] = routeQuery[p]
  })
  return utm_params
}

export default {
  init (_ctx) {
    setTimeout(() => { // espera um pouquinho pra setar os cookies e mandar
      const utm_params = _ctx.route && _ctx.route.query && getUTMParams(_ctx.route.query)
      let referer = document ? document.referrer : ''
      referer = referer && referer.indexOf('buser.com.br') < 0 ? referer : ''
      if (utm_params || referer) {
        api.everyone.saveUserAcquisitionData(utm_params || {}, referer)
      }
    }, 1000)
  }
}
