import EventBus from '~/helpers/eventbus'

const get = (key, { json = false, returnOnError = false } = {}) => {
  try {
    const val = window.localStorage.getItem(key)
    updateAmplitudeUser(key, val)
    return json ? JSON.parse(val) : val
  } catch (e) {
    // local storage pode não existir em browsers mais antigos
    return returnOnError
  }
}

const set = (key, val) => {
  try {
    window.localStorage.setItem(key, val)
    updateAmplitudeUser(key, val)
    return val
  } catch (e) {
    // local storage pode não existir em browsers mais antigos
  }
}

const setAb = (key, options) => {
  const actualVal = get(key)
  const option = options.includes(actualVal) ? actualVal : abOption(options)
  return set(key, option)
}

const updateAmplitudeUser = (key, val) => {
  EventBus.$emit('update-amplitude-user', { label: key, value: val })
}

const abOption = (options) => {
  let value
  const rng = Math.random()
  const count_options = options.length
  const prob_per_option = 1 / count_options
  for (let i = 0; i < count_options; i++) {
    const option = options[i]
    if (rng > i * prob_per_option && rng < (i + 1) * prob_per_option) {
      value = option
    }
  }
  return value
}

const getOrSet = (key, value, { json = false } = {}) => {
  const initValue = get(key, { json })
  return initValue === null ? set(key, value) : initValue
}

const del = (key) => {
  try {
    window.localStorage.removeItem(key)
  } catch (e) {
    // local storage pode não existir em browsers mais antigos
  }
}

const listKeys = () => {
  try {
    return Object.keys(window.localStorage)
  } catch (e) {
    // local storage pode não existir em browsers mais antigos
    return []
  }
}

const setLocalStorageWithExpiry = (key, value, ttl) => {
  const now = new Date()

  // Convertemos o ttl (proveniente do maxAgeInSeconds) para milisegundos porque
  // diferente do cookie-universal-nuxt, o Date.getTime() abaixo espera em
  // milisegundos
  ttl = ttl * 1000

  // `item` é um objeto que contem o valor origem e o tempo que ele deve expirar
  const item = {
    value: value,
    expiry: now.getTime() + ttl
  }
  set(key, JSON.stringify(item))
}

const getLocalStorageWithExpiry = (key) => {
  const itemStr = get(key)
  if (!itemStr) {
    return null
  }

  let item = null
  try {
    item = JSON.parse(itemStr)
  } catch { }
  const now = new Date()
  if (!item || !item.expiry || now.getTime() > item.expiry) {
    if (item?.expiry) {
      del(key)
    }
    return null
  }
  return item.value
}

const deleteExpiredKeys = () => {
  for (const key of listKeys()) {
    getLocalStorageWithExpiry(key)
  }
}

export default {
  get,
  set,
  setAb,
  getOrSet,
  del,
  listKeys,
  setLocalStorageWithExpiry,
  getLocalStorageWithExpiry,
  deleteExpiredKeys
}
