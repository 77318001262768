import api from '~api'

export const state = () => ({
  loading: false,
  origemSlug: null,
  destinoSlug: null,
  departureDate: null,
  returnDate: null,
  results: null,
  grupoIda: null,
  grupoVolta: null,
  completed: false,
  buscaIdaEVolta: false,
  selectedDate: null,
  searchResultCache: {},
  searching: false,
  badgesByDate: {},
  groupBadgeMap: {},
  usersSearching: null
})

export const mutations = {
  next (state, group) {
    if (!state.grupoIda) {
      state.grupoIda = group
      state.grupoIda.badge = state.groupBadgeMap[state.grupoIda.id]
    } else if (state.grupoIda.id !== group.id) {
      state.grupoVolta = group
      state.grupoVolta.badge = state.groupBadgeMap[state.grupoVolta.id]
    }
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setBuscaIdaEVolta (state, buscaIdaEVolta) {
    state.buscaIdaEVolta = buscaIdaEVolta
  },
  init (state, params) {
    state.origemSlug = params.origemSlug
    state.destinoSlug = params.destinoSlug
    state.departureDate = params.departureDate
    state.returnDate = params.returnDate
    state.grupoIda = null
    state.grupoVolta = null
    state.completed = false
    state.buscaIdaEVolta = false
    state.buscaPromocao = params.buscaPromocao
    state.searchResultCache = {}
  },
  setGruposByDate (state, grupos) {
    state.gruposByDate = grupos
  },
  setBadgesByDate (state, badges) {
    state.badgesByDate = badges
  },
  setSearchResult (state, result) {
    state.results = result
    state.searchResultCache = {
      ...state.searchResultCache,
      [cacheKeyFromState(state)]: result.groups_by_date
    }
  },
  setSelectedDate (state, date) {
    state.selectedDate = date
  },
  setSearching (state, isSearching) {
    state.searching = isSearching
  },
  setGroupBadge (state, { group, badge }) {
    state.groupBadgeMap[group.id] = badge.toLowerCase().split(' ').join('-')
  },
  setUsersSearching (state, usersSearching) {
    state.usersSearching = usersSearching
  }
}

export const actions = { // TODO: action nao eh usada
  next ({ commit }) {
    commit('next')
  },
  search ({ commit, dispatch, state, getters }, date) {
    commit('setSelectedDate', date)

    if (state.searchResultCache[cacheKeyFromState(state)]) { // Pesquisa já foi realizada
      return
    }

    commit('setSearching', true)

    let { origemSlug, destinoSlug } = state
    const isVolta = getters.step === 1

    if (isVolta) { // Seleciona a volta
      [origemSlug, destinoSlug] = [destinoSlug, origemSlug]
    }

    const params = {
      origemSlug,
      destinoSlug,
      departureDate: date
    }

    if (isVolta) {
      params.minClasse = state.grupoIda.tipo_assento
    }

    api
      .passenger
      .search(params)
      .then(result => {
        if (result) {
          commit('setSearchResult', result)
          // O hit inicial é feito no mounted da busca, para nunca rodar no SSR.
          if (process.browser) {
            dispatch('hit')
          }
        }
      })
      .finally(() => commit('setSearching', false))
  },
  selectTabDate ({ commit }, date) {
    commit('setSelectedDate', date)
  },

  commitResults ({ commit }, results) {
    if (results) {
      commit('setGruposByDate', results.groups_by_date)
      commit('setBadgesByDate', results.badges_by_date)
      commit('setSearchResult', results)
    }

    commit('setLoading', false)
  },
  hit ({ commit, state, getters }) {
    let { origemSlug, destinoSlug } = state

    if (!origemSlug || !destinoSlug) {
      return
    }

    const isVolta = getters.step === 1

    if (isVolta) {
      [origemSlug, destinoSlug] = [destinoSlug, origemSlug]
    }

    const params = {
      origem: origemSlug,
      destino: destinoSlug
    }
    const date_ida = state.selectedDate || state.departureDate
    if (date_ida) {
      params.date_ida = date_ida
      params.count = (
        state.results && state.results.counts && state.results.counts[date_ida]
      ) || 0
    }
    api.passenger.searchHit(params).then(response => {
      commit('setUsersSearching', response.users_searching)
    })
  }
}

export const getters = {
  step (state) {
    return state.grupoIda ? 1 : 0
  },
  completed (state) {
    // TODO mudar isso aqui
    return state.grupoIda && (!state.returnDate || state.grupoVolta)
  },
  grupoIda (state) {
    return state.grupoIda
  },
  returnDate (state) {
    return state.returnDate
  },
  departureDate (state) {
    return state.departureDate
  },
  gruposByDate (state) {
    return state.searchResultCache[cacheKeyFromState(state)]
  },
  buscaIdaEVolta (state) {
    return state.buscaIdaEVolta
  },
  buscaPromocao (state) {
    return state.buscaPromocao
  },
  usersSearching (state) {
    return state.usersSearching
  },
  badgesByDate (state) {
    return state.badgesByDate || {}
  },
  groupBadgeMap (state) {
    return state.groupBadgeMap || {}
  }
}

function cacheKeyFromState (state) {
  const searching = state.grupoIda === null ? 'ida' : 'volta'
  const date = state.selectedDate || state.departureDate

  return `${date}-${searching}`
}
