export default {
  setItem (key, value, days) {
    days = days || 60
    setCookie(key, value, days)
  },
  getItem (key) {
    return getCookie(key)
  },
  increment (key) {
    const value = parseInt(getCookie(key) || 0) + 1
    setCookie(key, value, 60)
    return value
  },
  decrement (key) {
    const value = parseInt(getCookie(key) || 0) - 1
    setCookie(key, value, 60)
    return value
  },
  getItemServer (ctx, key) {
    return getCookieServer(ctx, key)
  },
  removeItem (key) {
    return setCookie(key, '', 60)
  },
  getUTMParam (param) {
    const ckutm_params = getCookie('ckutm_params')

    if ((!ckutm_params || ckutm_params === 'null') && param !== 'utm_campaign') {
      return ''
    } else if (param === 'utm_campaign') {
      const utm_campaign = getCookie('ckutm_campaign')
      return utm_campaign && utm_campaign !== 'null' ? utm_campaign : ''
    }

    const utm_params = JSON.parse(ckutm_params)
    return utm_params[param] ? utm_params[param] : ''
  }
}

function setCookie (cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${cname}=${cvalue};${expires};path=/`
}

function getCookie (cname) {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function getCookieServer (ctx, cname) {
  const cookieheader = (ctx.req && ctx.req.headers && ctx.req.headers.cookie) || ''
  const cookiestring = RegExp(cname + '=[^;]+').exec(cookieheader)
  return decodeURIComponent(cookiestring ? cookiestring.toString().replace(/^[^=]+./, '') : '')
}
