import sentryhelper from '~/helpers/sentryhelper'

export default {
  onPromiseResolved (response) {
    if (process.server && response.headers.login_required) {
      const { url, params } = response.config
      const stackholder = new Error()
      sentryhelper.captureEvent({
        msg: `login_required no SSR ${url}`,
        tags: { ajaxurl: url },
        extra: {
          ajaxurl: url,
          stack: stackholder.stack,
          params
        }
      })
    }

    return response
  },
  onPromiseRejected (error, store) {
    const isNetworkError = !!error.isAxiosError && !error.response
    const { status, data } = error.response || {}
    const showBackendError = data && !!data.error && !data.silent

    if (showBackendError) {
      store.commit('toast/open', { message: data.error, color: 'error' })
      return error.response
    } else if (!status && !isNetworkError) {
      sentryhelper.captureEvent({
        msg: data.error || 'Erro estranho que não é resposta nem network',
        tags: {},
        extra: {
          data: data
        }
      })
      store.commit(
        'toast/open', {
          message: 'O servidor não conseguiu processar sua requisição.', color: 'error'
        }
      )
      return error.response
    }
    switch (status) {
      /*
      aqui a gente tem que retornar sempre a response pra não quebrar
      as requests do ajax que retornam res.data
      Se os endpoints checarem se a resposta tá vazia pra continuar, vai quebrar
      :sad-panda:. Retornar vazio quebra as chamadas da api com res => res.data
      porque data é uma propriedade do objeto response que retornamos aqui
      */
      case 400:
        store.commit('toast/open', {
          message: 'Dados incorretos. Verifique os dados enviados e tente novamente.',
          color: 'error'
        })
        break

      case 401:
        store.commit('toast/open', {
          message: 'Sua sessão expirou. Faça login novamente.', color: 'error'
        })
        store.commit('auth/removeCurrentUser')
        break

      case 403:
        store.commit('toast/open', {
          message: 'Você não tem permissão para acessar essa funcionalidade.',
          color: 'error'
        })
        break

      default:
        store.commit('toast/open', {
          message: 'Desculpe, ocorreu um erro desconhecido.', color: 'error'
        })
        sentryhelper.captureMessage(`Erro ${status}`)
        break
    }
    return error.response
  },
  onUnhandledRejection (error) {
    // Isso aqui não deveria acontecer nunca. Promises que não são axios devem ser
    // tratadas sempre
    sentryhelper.captureMessage(error)
  }
}
