import Vue from 'vue'
import * as Integrations from '@sentry/integrations'
import VERSION from '~/version'
import { isBlacklisted, isHTTPError, groupHTTPEvent } from '~/helpers/sentryerrorhandler'

let Sentry = null
if (process.server) {
  Sentry = require('@sentry/node')
} else {
  Sentry = require('@sentry/browser')
}

const TRACES_SAMPLE_RATE = 0.01
let ctx = null

const defaults = {
  release: VERSION,
  tracesSampleRate: TRACES_SAMPLE_RATE,
  ignoreErrors: [
    'Network Error',
    'Request aborted',
    'Request failed with status code 401',
    'timeout of 0ms exceeded',
    /^Loading( CSS)? chunk (\d)+ failed\./,
    'NavigationDuplicated',
    'Navigation cancelled from', // Navigation cancelled from "/perfil/amigos" to "/ajuda" with a new navigation.
    'via a navigation guard', // 'Redirected from "/perfil/entrar/password" to "/perfil/carteira" via a navigation guard.'
    'Avoided redundant navigation to current location',
    'Non-Error promise rejection captured' // Microsoft Outlook SafeLink Crawlers - https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/14
  ],

  beforeSend (event, hint) {
    if (isBlacklisted(event, hint)) {
      return null
    }

    if (isHTTPError(hint)) {
      return groupHTTPEvent(event, hint)
    }

    return event
  }
}

function setupNode ({ $config }) {
  if (process.sentry) {
    return
  }
  // o setupNode é chamado todo request
  // definir o process.sentry evita reprocessar o Sentry.init sempre
  process.sentry = Sentry
  const { SENTRY_DSN, ENVIRONMENT } = $config
  if (!SENTRY_DSN) {
    Sentry.init({ dsn: '' })
    console.log('sentry/node disabled')
    return
  }
  console.log(`init sentry/node ${SENTRY_DSN}`)
  const sentryOptions = {
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT || 'local',
    ...defaults
  }
  Sentry.init(sentryOptions)
  console.log('init sentry/node done')
}

function setupBrowser ({ $config }) {
  const { ENVIRONMENT, SENTRY_DSN } = $config

  if (!SENTRY_DSN) {
    Sentry.init({ dsn: '' })
    console.log('sentry/browser disabled')
    return
  }
  const sentryOptions = {
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT || 'local',
    integrations: [
      new Integrations.Vue({ Vue, attachProps: true })
    ],
    ...defaults
  }
  Sentry.init(sentryOptions)
  setUser()
  Sentry.configureScope(scope => {
    scope.setTag('runtime.name', 'browser')
  })
}

function setUser () {
  Sentry.configureScope(scope => {
    let { currentUser } = ctx.store.state.auth
    if (currentUser) {
      const { id, name, email, cell_phone } = currentUser
      currentUser = { id, name, email, cell_phone }
    }
    scope.setUser(currentUser)
    console.log('Sentry configured')
  })
}

export default {
  init (_ctx) {
    ctx = _ctx
    if (process.server) {
      setupNode(ctx)
    } else {
      setupBrowser(ctx)
    }
  },
  setUser,
  captureException (e) {
    Sentry.captureException(e)
  },
  captureMessage (msg) {
    Sentry.captureMessage(msg)
  },
  captureEvent ({ msg, tags, extra }) {
    console.log(`CAPTURE_EVENT ${msg}`)
    const event = { message: msg, level: 'error', tags, extra }
    Sentry.captureEvent(event)
  }
}
