<template>
  <v-layout row v-if="loggedIn">
    <v-btn
    fixed
    color="#f11075"
    class="chat-btn"
    @click="openChat()"
    >
      <p class="mb-0">Estou com dúvida</p>
    </v-btn>
  </v-layout>
</template>

<script>
import EventBus from '~/helpers/eventbus'
import hichathelper from '~/helpers/hichathelper'

export default {
  computed: {
    loggedIn () {
      return this.$store.getters['auth/loggedIn']
    }
  },
  methods: {
    openChat () {
      hichathelper.openHiChat(this.$store)
      this.emitEvent()
    },
    emitEvent (chatType = 'chat') {
      this.$emit('click-chat-type', { type: chatType })
      EventBus.$emit('fale-pelo-chat', { type: 'hichat' })
    }
  }
}

</script>

<style scoped>

.chat-btn {
  margin: 12px;
  color: white;
  bottom: 0;
  right: 0;
}

</style>
