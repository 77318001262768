const ignore400 = [
  '^/api/save_user',
  '^/api/complete_registration',
  '^/api/validate_reset_password_token',
  '^/api/reset_password',
  '^/api/resend_phone_confirmation',
  '^/api/confirm_invitation_claim'
]
const ignore404 = [
  '^/api/promo/.*$'
]

export function isBlacklisted (event, hint) {
  const ex = getException(hint)
  const url = getExceptionUrl(ex)
  const status = getExceptionStatus(ex)
  const exceptionMessage = getExceptionMessage(ex)

  if (exceptionMessage && exceptionMessage.includes("Cannot read property 'focus' of undefined")) {
    const componentName = event &&
      event.contexts &&
      event.contexts.vue &&
      event.contexts.vue.componentName

    return componentName === 'v-text-field'
  }

  switch (status) {
    case 400:
      return ignore400.some(p => url.match(p))

    case 401:
      return true

    case 404:
      return ignore404.some(p => url.match(p))

    default:
      return false
  }
}

// Valida se a exception é um erro HTTP verificando a presença
// do campo `response.status` definido na `exception`
export function isHTTPError (hint) {
  const ex = getException(hint)
  const status = getExceptionStatus(ex)

  return !!status
}

export function groupHTTPEvent (event, hint) {
  const ex = getException(hint)
  const status = getExceptionStatus(ex)
  const method = getExceptionMethod(ex)
  const url = getExceptionUrl(ex)

  event.fingerprint = [
    status,
    method
  ]

  if (url) {
    event.fingerprint.push(getAPIEndpointUrl(url))
  }

  return event
}

// Pega a forma mais basica da url do endpoint para facilitar o agrupamento
// Invés de pegar isso "/api/confirm_transaction/69568/668647"
// Pega isso "/api/confirm_transaction"
function getAPIEndpointUrl (url) {
  return url.split('/').splice(0, 3).join('/')
}

function getException (hint) {
  return hint && hint.originalException
}

function getExceptionStatus (ex) {
  if (!ex || !ex.response) {
    return null
  }

  return ex.response.status
}

function getExceptionMethod (ex) {
  if (!ex || !ex.response || !ex.response.config) {
    return null
  }

  return ex.response.config.method
}

function getExceptionUrl (ex) {
  if (!ex || !ex.response || !ex.response.config) {
    return null
  }

  return ex.response.config.url
}

function getExceptionMessage (ex) {
  if (!ex || !ex.message) {
    return null
  }

  return ex.message
}
