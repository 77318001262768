<template>
  <v-avatar :size="sizePx">
    <img
      :width="sizeInternal"
      :height="sizeInternal"
      alt="imagem de perfil"
      title="imagem de perfil"
      :src="imgUrl"
    />
   </v-avatar>
</template>

<script>

export default {
  props: ['url', 'size'],
  computed: {
    sizePx () {
      return `${this.sizeInternal}px`
    },
    sizeInternal () {
      return this.size || 36
    },
    imgUrl () {
      if (!this.url) {
        return this.defaultAvatar
      }
      // reduz o avatar do facebook
      // https://graph.facebook.com/4/picture?width=300&height=300
      if (this.url.indexOf('graph.facebook.com') !== -1) {
        return this.url.replace(/=300/g, '=' + this.sizeInternal)
      }
      return this.url
    },
    defaultAvatar () {
      return require('~/assets/images/empty-photo.jpg')
    }
  }
}
</script>
