<template>
  <div>
    <v-bottom-nav app v-if="loggedIn" v-model="options.bottomnavItem" :active="options.bottomnavItem" color="white" class="hidden-md-and-up">
      <v-btn flat color="primary" value="home" @click="change('home')">
        <span>Início</span>
        <fa class="fs-m mb-1" :icon="['fal', 'home']" />
      </v-btn>
      <v-btn flat color="primary" value="travels" @click="change('nextTravels')">
        <span>Viagens</span>
        <fa class="fs-m mb-1" :icon="['fal', 'bus']" />
      </v-btn>
      <v-btn flat color="primary" value="carteira" @click="change('carteira')">
        <span>Carteira</span>
        <fa class="fs-m mb-1" :icon="['fal', 'wallet']" />
      </v-btn>
      <v-btn flat color="primary" value="friends" @click="change('promocoes')">
        <span>Promoções</span>
        <fa class="fs-m mb-1" :icon="['fal', 'badge-percent']" />
      </v-btn>
      <v-btn v-if="loggedIn && isCompany" flat color="primary" href="https://parceiro.buser.com.br">
        <span>Parceiro</span>
        <fa class="fs-m mb-1" :icon="['fal', 'briefcase']" />
      </v-btn>
      <v-btn flat color="primary" value="ajuda" @click="change('ajuda')">
        <span>Ajuda</span>
        <fa class="fs-m mb-1" :icon="['fal', 'question-circle']" />
      </v-btn>
    </v-bottom-nav>
  </div>
</template>

<script>
import routehelper from '~/helpers/routehelper.js'

export default {
  computed: {
    options () {
      return this.$route.meta
    },
    loggedIn () {
      return this.$store.getters['auth/loggedIn']
    },
    isCompany () {
      return this.loggedIn && this.$store.state.auth.currentUser.permissions.COMPANY
    }
  },
  methods: {
    change (value) {
      routehelper.push(value, this.$route.name, null, null, this.$router)
    }
  }
}
</script>

<style lang="scss" scoped>
.popover-icon {
  position: relative;

  &.is-active {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: #f00;
    }
  }
}

@media (max-width: 400px) {
  .bottom-nav .btn {
    min-width: 0;
    width: auto;
  }
  .bottom-nav .btn--active .btn__content span {
    font-size: 12px;
  }
}
</style>
