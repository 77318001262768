export const state = () => ({
})

export const mutations = {
  set (state, vars) {
    for (const k in vars) {
      state[k] = vars[k]
    }
  }
}

export const getters = {
}
