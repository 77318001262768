import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faStar as freeFarFaStar,
  faQuestionCircle as freeFarFaQuestionCircle
} from '@fortawesome/free-regular-svg-icons'

import {
  faAngleRight as freeFasFaAngleRight,
  faChevronRight as freeFasFaChevronRight,
  faUsers as freeFasFaUsers,
  faBus as freeFasFaBus,
  faComment as freeFasFaComment,
  faArrowRight as freeFasFaArrowRight,
  faBell as freeFasFaBell,
  faHome as freeFasFaHome,
  faUser as freeFasFaUser
} from '@fortawesome/free-solid-svg-icons'

import {
  faFacebookF as freeFabFaFacebookF,
  faInstagram as freeFabFaInstagram,
  faWhatsapp as freeFabFaWhatsapp,
  faYoutube as freeFabFaYoutube,
  faLinkedinIn as freeFabFaLinkedinIn,
  faTwitter as freeFabFaTwitter,
  faAndroid as freeFabFaAndroid,
  faApple as freeFabFaApple
} from '@fortawesome/free-brands-svg-icons'

import {
  faHome as proFalFaHome,
  faBus as proFalFaBus,
  faPrint as proFalFaPrint,
  faWallet as proFalFaWallet,
  faBadgePercent as proFalFaBadgePercent,
  faQuestionCircle as proFalFaQuestionCircle,
  faBriefcase as proFalFaBriefcase,
  faInfoCircle as proFalFaInfoCircle,
  faExchange as proFalFaExchange,
  faExclamationTriangle as proFalFaExclamationTriangle,
  faExclamationCircle as proFalFaExclamationCircle,
  faQrcode as proFalFaQrcode
} from '@fortawesome/pro-light-svg-icons'

import {
  faGiftCard as proFasFaGiftCard,
  faDownload as proFasFaDownload
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  freeFarFaStar,
  freeFarFaQuestionCircle,
  freeFasFaAngleRight,
  freeFasFaChevronRight,
  freeFasFaUsers,
  freeFasFaBus,
  freeFasFaComment,
  freeFasFaArrowRight,
  freeFasFaBell,
  freeFasFaHome,
  freeFasFaUser,
  freeFabFaFacebookF,
  freeFabFaInstagram,
  freeFabFaWhatsapp,
  freeFabFaYoutube,
  freeFabFaLinkedinIn,
  freeFabFaTwitter,
  freeFabFaAndroid,
  freeFabFaApple,
  proFalFaHome,
  proFalFaBus,
  proFalFaPrint,
  proFalFaWallet,
  proFalFaBadgePercent,
  proFalFaQuestionCircle,
  proFalFaBriefcase,
  proFalFaInfoCircle,
  proFalFaExchange,
  proFalFaExclamationTriangle,
  proFalFaExclamationCircle,
  proFalFaQrcode,
  proFasFaGiftCard,
  proFasFaDownload
)

config.autoAddCss = false

Vue.component('fa', FontAwesomeIcon)
Vue.component('faLayers', FontAwesomeLayers)
Vue.component('faLayersText', FontAwesomeLayersText)
