export const state = () => ({
  tests: {}
})

export const mutations = {
  set (state, props) {
    state.tests = { ...state.tests, ...props }
  }
}

export const actions = {
  addTest ({ commit }, props) {
    commit('set', props)
  }
}

export const getters = {
  getByLabel: (state) => (label) => {
    return state.tests[label]
  }
}
