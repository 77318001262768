import api from '~api'
import isEmpty from 'lodash/isEmpty'
import passengerhelper from '~/helpers/passengerhelper'

export const state = () => ({
  currentUser: undefined,
  passengers: []
})

export const mutations = {
  setCurrentUser (state, user) {
    state.currentUser = user

    if (user) {
      if (user.permissions === undefined) {
        user.permissions = {}
      }
    }
    if (!isEmpty(user.cached_passengers)) {
      state.passengers = user.cached_passengers
    }
  },
  updateCurrentUser (state, props) {
    Object.assign(state.currentUser, props)
  },
  removeCurrentUser (state) {
    state.currentUser = null
    state.passengers = []
  },
  dontAskInviteCode (state) {
    state.currentUser.ask_invite_code = false
  },
  setInviteCode (state, code) {
    state.currentUser.invitecode = code
  },
  setPassengers (state, passengers) {
    state.passengers = passengers
  },
  setNextTravel (state, travel) {
    state.currentUser.next_travel = travel
  }
}

export const getters = {
  loggedIn (state) {
    return !!(state.currentUser && state.currentUser.permissions)
  },
  userId (state) {
    return state.currentUser ? state.currentUser.id : null
  },
  searchData (state) {
    return state.currentUser ? state.currentUser.search_data : null
  },
  hasNextTravel (state, getters) {
    return getters.loggedIn && !!state.currentUser.next_travel
  },
  userPhone (state) {
    return state.currentUser ? state.currentUser.cell_phone : null
  },
  isFirstTravel (state) {
    return state.currentUser && state.currentUser.first_travel
  },
  isRevendedor (state, getters) {
    return getters.loggedIn && state.currentUser.permissions.REVENDEDOR
  },
  passengerWithRG: (state) => (rg, passengerId) => {
    if (!rg) return false
    if (passengerId) return state.passengers.find(passenger => passenger.rg_number === rg && passenger.id !== passengerId)
    return state.passengers.find(passenger => passenger.rg_number === rg)
  },
  passengerWithCPF: (state) => (cpf, passengerId) => {
    if (!cpf) return false
    if (passengerId) return state.passengers.find(passenger => passenger.cpf === cpf && passenger.id !== passengerId)
    return state.passengers.find(passenger => passenger.cpf === cpf)
  }
}

export const actions = {
  whoami ({ commit }) {
    return api.auth.whoami().then(data => {
      if (data.authenticated) {
        commit('setCurrentUser', data.user)
      } else {
        commit('removeCurrentUser')
        commit('lead/removeCurrentLead', null, { root: true })
        commit('revenda/removeCurrentUser', null, { root: true })
      }

      return data
    })
  },
  fetchPassengers ({ commit }) {
    return api.auth.passengers().then(data => commit('setPassengers', passengerhelper.updatePassenger(data)))
  },
  logout ({ commit }) {
    return api.auth.logout().then(() => {
      commit('removeCurrentUser')
      commit('reservation/resetReservationData', null, { root: true })
    })
  },
  updateNextTravel ({ commit }) {
    return api.auth.whoami(['next_travel']).then(data => {
      if (!data.authenticated || !data.user) {
        return null
      }

      const travel = data.user.next_travel
      commit('setNextTravel', travel)

      return travel
    })
  }
}
