import * as amplitude from 'amplitude-js'
import cookiehelper from '~/helpers/cookiehelper.js'

let ctx = null
const DEVICE_ID_COOKIE_KEY = 'amplitude_device_id'

export default {
  init (_ctx) {
    ctx = _ctx
    const { AMPLITUDE_API_KEY } = ctx.store.state.envs

    if (AMPLITUDE_API_KEY) {
      const userId = this.getUserId()
      const deviceId = cookiehelper.getItem(DEVICE_ID_COOKIE_KEY)

      amplitude.getInstance().init(AMPLITUDE_API_KEY, userId, {
        deviceId,
        includeReferrer: true,
        includeUtm: true,
        saveParamsReferrerOncePerSession: false // Começando atribuição multi-touch: https://developers.amplitude.com/docs/web-attribution
      }, () => {
        if (!deviceId) {
          cookiehelper.setItem(DEVICE_ID_COOKIE_KEY, amplitude.getInstance().options.deviceId)
        }
      })

      this.identifyUser()
    } else {
      console.log('amplitude_api_key not set')
    }
  },
  getUserId () {
    let userId = null
    const { currentUser } = ctx.store.state.auth
    if (currentUser) {
      userId = `user_${currentUser.id}`
    }
    return userId
  },
  setUserId (userId = this.getUserId()) {
    amplitude.setUserId(userId)
  },
  identifyUser () {
    let userEmail = null
    let userPhone = null
    const { currentUser } = ctx.store.state.auth
    if (currentUser) {
      userEmail = currentUser.email
      userPhone = currentUser.cell_phone
    }
    const user = new amplitude.Identify().set('email', userEmail).set('phone', userPhone)
    amplitude.identify(user)
  },
  setProperty (key, value) {
    const user = new amplitude.Identify().set(key, value)
    amplitude.identify(user)
  },
  cleanData (data) {
    if (data) {
      delete data.locais
      if (data.grupo) {
        delete data.grupo.locais
      }
    }
    return data
  },
  logEvent (eventType, eventData, immediately) {
    if (immediately) {
      amplitude.getInstance().logEvent(eventType, eventData)
    } else {
      // Uma gambiarra está por vir
      // Aguardar um pouco antes de mandar os eventos pra ele setar o user properties antes
      setTimeout(() => {
        amplitude.getInstance().logEvent(eventType, eventData) // dá pra colocar uma função de callback como último argumento
      }, 3000)
    }
  }
}
