export default ({ req, store, redirect }) => {
  const url = req ? req.url : `${window.location.pathname}${window.location.search}`
  if (!store.getters['settings/loaded'] && !url.startsWith('/erro')) {
    return store.dispatch('settings/load').catch(e => {
      if (process.server) {
        console.log(`settings middleware error on ${url}`)
        return redirect({ name: 'errorhome', query: { msg: 'Ocorreu um erro ao carregar as configurações' } })
      }
    })
  }
}
