export default ({ store, redirect, req, route }) => {
  const url = req ? req.url : `${window.location.pathname}${window.location.search}`
  const currentUser = store.state.auth.currentUser
  const completeRegistration = currentUser && currentUser.complete_registration
  if (completeRegistration && !url.startsWith('/perfil/completar-cadastro')) {
    return redirect('/perfil/completar-cadastro', { next: url })
  }
  if (!completeRegistration && route.name === 'completeRegistration') {
    return redirect('/')
  }
}
