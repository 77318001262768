/*
Middleware para evitar duplicação de URLs causada pela alteração de slugs, que
antes tinham parênteses mas não tem mais.

Pode ser removido depois de 2021-07-01.
*/
export default ({ redirect, route, query }) => {
  if (!process.server) {
    return
  }
  const cleanPath = route.path.replace(/[()]/g, '').replace(/_/g, '-')
  if (cleanPath !== route.path) {
    return redirect(301, cleanPath, query)
  }
}
