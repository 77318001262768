import dayjs from 'dayjs'

export default function (ctx) {
  let invitecode = ctx.query.convite
  if (!invitecode && ctx.route.params.invitecode) {
    invitecode = ctx.route.params.invitecode
  }
  if (invitecode) {
    const expires = '' + (dayjs().add(3 * 24 * 60 * 60 * 1000).valueOf())
    if (process.server) {
      ctx.res.setHeader('Set-Cookie', [`invitecode=${invitecode};path=/;expires=${expires}`])
    } else {
      document.cookie = `invitecode=${invitecode};path=/;expires=${expires}`
    }
  }
}
