import EventBus from '~/helpers/eventbus.js'
import amplitudehelper from '~/helpers/amplitudehelper'

const getGroupInfo = group => {
  if (!group) {
    return {}
  }
  const origem = group.origem ? group.origem.slug : null
  const destino = group.destino ? group.destino.slug : null
  const datetime_ida = group.datetime_ida || group.departureDate
  const params = {
    origem,
    destino,
    badge: group.badge,
    trecho: origem && destino ? (origem > destino ? `${destino}=${origem}` : `${origem}=${destino}`) : '',
    name: `${origem} - ${destino} ${datetime_ida}`,
    data: datetime_ida,
    grupo_id: group.grupo_id,
    grupo_classe_id: group.id,
    datetime_ida: datetime_ida,
    departureDate: datetime_ida,
    returnDate: group.returnDate || null,
    tipo_assento: group.tipo_assento,
    probability: group.confirming_probability,
    current_split: group.max_split_value,
    max_split_value: group.max_split_value,
    value: group.max_split_value, // convenção do Facebook
    reservations_last_day: group.reservations_last_day,
    vagas: group.vagas,
    preco_rodoviaria: group.preco_rodoviaria,
    currency: 'BRL',
    status: group.status,
    error: group.error,
    nota_avaliacao: group.nota_avaliacao
  }
  return params
}

const getTravelInfo = travel => {
  const info = getGroupInfo(travel.grupo)
  info.promocao = travel.promocao
  info.is_volta = !!travel.is_volta
  info.probability = travel.probability_on_reservation
  info.error = travel.error
  if (travel.payment) {
    const p = travel.payment
    info.valor_pagamento = p.value
    info.value = p.value
    info.valor_integral = travel.max_split_value
    info.payment_method = p.method
    info.parcelas = p.parcela_count
    info.parcela_value = p.parcela_value
  }
  return info
}

const getTrechoInfo = trecho => {
  const origem = trecho.origem ? trecho.origem.slug : null
  const destino = trecho.destino ? trecho.destino.slug : null
  const params = {
    origem,
    destino,
    trecho: origem && destino ? (origem > destino ? `${destino}=${origem}` : `${origem}=${destino}`) : '',
    photo: trecho.photo,
    photo_cc: trecho.photo_cc,
    preco_rodoviaria: trecho.preco_rodoviaria,
    rateio: trecho.rateio,
    tipo_assento: trecho.tipo_assento
  }
  return params
}

function _google_event (o) {
  if (window.dataLayer) {
    window.dataLayer.push(o)
  }
}

function _ampUserLoggedIn () {
  amplitudehelper.setUserId()
  amplitudehelper.identifyUser()
}

function _ampUserLoggedOut () {
  amplitudehelper.setUserId(null)
}

function _amplitude_event (eventType, eventData, immediately) {
  amplitudehelper.logEvent(eventType, eventData, immediately)
}

function _send (type, data, {
  amplitude = false,
  google = false,
  immediately = false
} = {}) {
  const grupo = data && data.grupo
  const travel = data && data.travel
  const reserva = data && data.reserva
  const trecho = data && data.trecho
  let eventData = {}
  if (travel) {
    eventData = { ...getTravelInfo(travel) }
  } else if (grupo) {
    eventData = { ...getGroupInfo(grupo) }
  } else if (reserva) {
    eventData = {
      grupo_ida: reserva.grupoIda ? getGroupInfo(reserva.grupoIda) : null,
      grupo_volta: reserva.grupoVolta ? getGroupInfo(reserva.grupoVolta) : null,
      date_diff: reserva.dateDiff || null
    }
  } else if (trecho) {
    eventData = { ...getTrechoInfo(trecho) }
  }
  if (amplitude) {
    const ampData = { ...eventData, ...amplitudehelper.cleanData(data) }
    _amplitude_event(type, ampData, immediately)
  }
  if (google) {
    let params = {}
    if (travel) {
      params = {
        id: travel.id,
        ...getGroupInfo(travel.grupo)
      }
    }
    _google_event({ ...params, event: type })
  }
}

EventBus.$on('click-search', grupo => {
  if (!grupo) {
    return {}
  }
  _send('click-search', { grupo }, { amplitude: false })
})

EventBus.$on('click-grupo-destaque', grupo => {
  _send('click-grupo-destaque', { grupo }, {})
})

EventBus.$on('click-recent-search', grupo => {
  _send('click-recent-search', { grupo }, {})
})

EventBus.$on('open-search-result-page', data => {
  _send('open-search-result-page', data, { amplitude: true })
})

EventBus.$on('click-view-group', grupo => {
  _send('click-view-group', { grupo })
})

EventBus.$on('acessou-adicionar-passageiros', reserva => {
  _send('acessou-adicionar-passageiros', { reserva }, { amplitude: true })
})

EventBus.$on('click-confirm-passenger-data', data => {
  _send('click-confirm-passenger-data', data)
})

EventBus.$on('payment-error', grupo => {
  _send('payment-error', { grupo })
})

EventBus.$on('solicitou-reserva', travel => {
  _send('solicitou-reserva', { travel, grupo: travel.grupo, value: travel.max_split_value }, { amplitude: true, google: true })
  // evento do google analytics ecommerce
  const travelInfo = getTravelInfo(travel)
  _google_event({
    ...getGroupInfo(travel.grupo),
    event: 'purchase',
    id: travel.id,
    transactionId: travel.id,
    typeSale: travel.grupo.modelo_venda,
    transactionAffiliation: travelInfo.trecho,
    transactionTotal: travel.max_split_value,
    conversionValue: travel.max_split_value,
    isFirstTravel: travel.isFirstTravel,
    transactionProducts: [
      {
        id: travel.id,
        name: travelInfo.name,
        category: 'Travel',
        price: travel.max_split_value,
        quantity: 1
      }
    ]
  })
})

EventBus.$on('purchase-confirmed', travel => {
  _send('purchase-confirmed', { travel, grupo: travel.grupo, value: travel.max_split_value })
})

EventBus.$on('click-share-facebook', params => {
  _send('click-share-facebook', params, { amplitude: true })
})

EventBus.$on('click-share-whatsapp', params => {
  _send('click-share-whatsapp', params, { amplitude: true })
})

EventBus.$on('click-share-link', params => {
  _send('click-share-link', params, { amplitude: true })
})

EventBus.$on('click-install-app-banner', params => {
  _send('click-install-app-banner', params, {})
})

EventBus.$on('registration-completed', params => {
  _send('registration-completed', params, {})
})

EventBus.$on('phone-confirmed', params => {
  _send('phone-confirmed', params, {})
})

EventBus.$on('sugeriu-trecho', params => {
  _send('sugeriu-trecho', params, { amplitude: true })
})

EventBus.$on('clicou-trecho-gratis', trecho => {
  _send('clicou-trecho-gratis', { trecho }, { amplitude: true })
})

EventBus.$on('acessou-home', params => {
  _send('acessou-home', params, { amplitude: true })
})

EventBus.$on('acessou-sobre', params => {
  _send('acessou-sobre', params, {})
})

EventBus.$on('acessou-ajuda', params => {
  _send('acessou-ajuda', params, {})
})

EventBus.$on('enviou-email-ajuda', params => {
  _send('enviou-email-ajuda', params, { amplitude: true })
})

EventBus.$on('clicou-enviar-email', () => {
  _send('clicou-enviar-email', {}, { amplitude: true })
})

EventBus.$on('acessou-termos', params => {
  _send('acessou-termos', params)
})

EventBus.$on('acessou-carteira', params => {
  _send('acessou-carteira', params)
})

EventBus.$on('acessou-viaje-gratis', params => {
  _send('acessou-viaje-gratis', params)
})

EventBus.$on('acessou-parceiros', () => {
  _send('acessou-parceiros', {}, { amplitude: true })
})

EventBus.$on('acessou-form-fretamento-colaborativo', () => {
  _send('acessou-form-fretamento-colaborativo', {}, { amplitude: true })
})

EventBus.$on('acessou-form-marketplace', () => {
  _send('acessou-form-marketplace', {}, { amplitude: true })
})

EventBus.$on('adicionou-cupom', params => {
  _send('adicionou-cupom', params, { amplitude: true })
})

EventBus.$on('acessou-viagens-proximas', params => {
  _send('acessou-viagens-proximas', params, {})
})

EventBus.$on('acessou-viagens-anteriores', params => {
  _send('acessou-viagens-anteriores', params, {})
})

EventBus.$on('open-ongoing-travel', travel => {
  _send('open-ongoing-travel', { travel }, {})
})

EventBus.$on('opened-help-chat', travel => {
  _send('opened-help-chat', { travel }, { amplitude: true })
})

EventBus.$on('boarding-message-sent', params => {
  _send('boarding-message-sent', { travel: params.travel, text: params.text })
})

EventBus.$on('travel-feedback', params => {
  _send('travel-feedback', { ...params.feedback, travel: params.travel })
})

EventBus.$on('acessou-travel', travel => {
  _send('acessou-travel', { travel }, { amplitude: true })
})

EventBus.$on('travel-canceled', params => {
  _send('travel-canceled', params, { amplitude: true })
})

EventBus.$on('escolheu_alterar_reserva', travel => {
  _send('escolheu_alterar_reserva', { travel }, { amplitude: true })
})

EventBus.$on('escolheu_falar_zap', travel => {
  _send('escolheu_falar_zap', { travel }, { amplitude: true })
})

EventBus.$on('passenger-removed', travel => {
  _send('passenger-removed', { travel, reason: 'BY_USER_REQUEST' }, { amplitude: true })
})

EventBus.$on('alterar-reserva', travel => {
  _send('alterar-reserva', { travel }, { amplitude: true })
})

EventBus.$on('alterar-reserva-data-selecionada', travel => {
  _send('alterar-reserva-data-selecionada', { travel }, { amplitude: true })
})

EventBus.$on('alterar-reserva-grupo-selecionado', grupo => {
  _send('alterar-reserva-grupo-selecionado', { grupo }, { amplitude: true })
})

EventBus.$on('alterar-reserva-pagamento-confirmado', () => {
  _send('alterar-reserva-pagamento-confirmado', {}, { amplitude: true })
})

EventBus.$on('alterar-reserva-alterada', params => {
  _send('alterar-reserva-alterada', params, { amplitude: true })
})

EventBus.$on('colocou-contato-na-travel', travel => {
  _send('colocou-contato-na-travel', { travel }, { amplitude: true })
})

EventBus.$on('recebeu-form-contato-na-travel', travel => {
  _send('recebeu-form-contato-na-travel', { travel }, { amplitude: true })
})

EventBus.$on('view-mapa-embarque', grupo => {
  _send('view-mapa-embarque', { grupo })
})

EventBus.$on('save-passenger', passenger => {
  _send('save-passenger', passenger, { amplitude: true })
})

EventBus.$on('saque-solicitado', params => {
  _send('saque-solicitado', params)
})

EventBus.$on('saque-confirmado', params => {
  _send('saque-confirmado', params)
})

EventBus.$on('acessou-cadastro', () => {
  _send('acessou-cadastro', {}, { amplitude: true })
})

EventBus.$on('cadastro', params => {
  _ampUserLoggedIn()
  _send('cadastro', params, { amplitude: true, google: true })
})

EventBus.$on('login', () => {
  _send('login', {}, { amplitude: true })
  _ampUserLoggedIn()
})

EventBus.$on('login-via-token', () => {
  _send('login-via-token', {}, { amplitude: true })
  _ampUserLoggedIn()
})

EventBus.$on('logout', () => {
  _send('logout', {}, { amplitude: true })
  _ampUserLoggedOut()
})

EventBus.$on('resultados-pesquisa-clicou-detalhes-grupo', grupo => {
  _send('resultados-pesquisa-clicou-detalhes-grupo', { grupo }, { amplitude: true })
})

EventBus.$on('acessou-pagamento', reserva => {
  _send('acessou-pagamento', { reserva }, { amplitude: true })
})

EventBus.$on('acessou-compra-confirmada', reserva => {
  _send('acessou-compra-confirmada', { reserva }, { amplitude: true })
})

EventBus.$on('started-purchase-as-visitor', () => {
  _send('started-purchase-as-visitor', {}, { amplitude: false })
})

EventBus.$on('login-as-visitor', () => {
  _send('login-as-visitor', {}, { amplitude: true })
  _ampUserLoggedIn()
})

EventBus.$on('lead-inscreveu-campanha-sul', email => {
  _send('lead-inscreveu-campanha-sul', { email })
})

EventBus.$on('acessou-promocao', data => {
  _send('acessou-promocao', data, { amplitude: true })
})

EventBus.$on('update-amplitude-user', props => {
  amplitudehelper.setProperty(props.label, props.value)
})

EventBus.$on('ativou-promocao', data => {
  _send('ativou-promocao', data, { amplitude: true })
})

EventBus.$on('acessou-promocao-form', data => {
  _send('acessou-promocao-form', data, { amplitude: true })
})

EventBus.$on('campanha-artesp-acessou', () => {
  _send('campanha-artesp-acessou', {}, { amplitude: true })
})

EventBus.$on('campanha-artesp-enviou', () => {
  _send('campanha-artesp-enviou', {}, { amplitude: true })
})

EventBus.$on('campanha-artesp-compartilhou', data => {
  _send('campanha-artesp-compartilhou', data, { amplitude: true })
})

EventBus.$on('pix-visualizou', () => {
  _send('pix-visualizou', {}, { amplitude: true })
})

EventBus.$on('wtp-visualizou', () => {
  _send('wtp-visualizou', {}, { amplitude: true })
})

EventBus.$on('revendedor-visualizou', () => {
  _send('revendedor-visualizou', {}, { amplitude: true })
})

EventBus.$on('revendedor-visualizou-info-pax', () => {
  _send('revendedor-visualizou-info-pax', {}, { amplitude: true })
})

EventBus.$on('pix-compartilhou', () => {
  _send('pix-compartilhou', {}, { amplitude: true })
})

EventBus.$on('wtp-compartilhou', () => {
  _send('wtp-compartilhou', {}, { amplitude: true })
})

EventBus.$on('popup-lead-visualizou', local => {
  const event = `popup-lead-visualizou-${local}`
  _send(event, {}, { amplitude: true })
})

EventBus.$on('popup-lead-cadastrou-telefone', () => {
  _send('popup-lead-cadastrou-telefone', {}, { amplitude: true })
})

EventBus.$on('popup-lead-cadastrou-trecho', () => {
  _send('popup-lead-cadastrou-trecho', {}, { amplitude: true })
})

EventBus.$on('clicked-payment-tab', tabName => {
  const event = `pagamento-clicou-aba-${tabName}`
  _send(event, {}, { amplitude: true })
})

EventBus.$on('clicou-copiar-codigo-pix', () => {
  _send('clicou-copiar-codigo-pix', {}, { amplitude: true })
})

EventBus.$on('open-whatsapp-message-popup', () => {
  _send('open-chat-message-popup', {}, { amplitude: true })
})

EventBus.$on('promocoes-viagem-proxima', () => {
  _send('promocoes-viagem-proxima', {}, { amplitude: true })
})

EventBus.$on('sent-message-to-whatsapp', mensagem => {
  _send('sent-message-to-whatsapp', { mensagem }, { amplitude: true })
})

EventBus.$on('zap-ajuda-click', () => {
  _send('zap-ajuda-click', {}, { amplitude: true })
})

EventBus.$on('ainda-com-duvida', () => {
  _send('ainda-com-duvida', {}, { amplitude: true })
})

EventBus.$on('expandir-topico-ajuda', data => {
  _send('expandir-topico-ajuda', data, { amplitude: false })
})

EventBus.$on('acessou-ajuda-por', data => {
  _send('acessou-ajuda-por', data, { amplitude: true })
})

EventBus.$on('question-util', data => {
  _send('question-util', data, { amplitude: true })
})

EventBus.$on('clicou-recursos-onibus', () => {
  _send('clicou-recursos-onibus', {}, { amplitude: true })
})

EventBus.$on('clicou-documentos-permitidos', () => {
  _send('clicou-documentos-permitidos', {}, { amplitude: true })
})

EventBus.$on('ajuda-pagina-reserva', data => {
  _send('ajuda-pagina-reserva', data, { amplitude: true })
})
EventBus.$on('click-show-social-name', data => {
  _send('click-show-social-name', data, { amplitude: true })
})

EventBus.$on('click-hide-social-name', data => {
  _send('click-hide-social-name', data, { amplitude: true })
})

EventBus.$on('clicou-chat-ajuda', data => {
  _send('clicou-chat-ajuda', data, { amplitude: true })
})

EventBus.$on('push-abriu-chat-ajuda', data => {
  _send('push-abriu-chat-ajuda', data, { amplitude: true })
})

EventBus.$on('clicou-ver-numero-achados-e-perdidos', data => {
  _send('clicou-ver-numero-achados-e-perdidos', data, { amplitude: true })
})

EventBus.$on('lead-via-landing-page', () => {
  _send('lead-via-landing-page', {}, { amplitude: true, immediately: true })
})

EventBus.$on('abriu-popup-99', data => {
  _send('abriu-popup-99', data, { amplitude: true })
})

EventBus.$on('copiou-codigo-cupom-99', data => {
  _send('copiou-codigo-cupom-99', data, { amplitude: true })
})

EventBus.$on('clicou-link-abrir-99', data => {
  _send('clicou-link-abrir-99', data, { amplitude: true })
})

EventBus.$on('selecionou-grupo', grupo => {
  _send('selecionou-grupo', { grupo }, { amplitude: true })
})

EventBus.$on('acessou-landing-page', data => {
  _send('acessou-landing-page', data, { amplitude: true })
})

// Outros
export default ctx => {
  amplitudehelper.init(ctx)
}
