<template>
  <div>
    <div v-if="cellPhoneConfirmationPending && showAlert" class="alert-phone bg-blue text-white">
      <p class="ma-0">Confirme o celular pelo SMS recebido.
        <a href="javascript:;"
          @click.prevent="resend"
          target="_blank"
          class="alert-link text-white"
          :class="{'text-light-blue al-disabled' : disableResend }">
          {{ disableResend ? `Reenviar (Aguarde ${timeout}s)` : 'Reenviar' }}
        </a>
      </p>
      <v-icon class="alert-close text-white" @click="dismiss">close</v-icon>
    </div>
    <phone-confirmation-popup @sent="resetTimeout" ref="phoneConfirmationPopup" />
  </div>
</template>

<script>
import phoneConfirmationPopup from '~/components/login/phone-confirmation-popup'

export default {
  props: {
    showAlert: {
      type: Boolean,
      default: false
    }
  },
  components: {
    phoneConfirmationPopup
  },
  data () {
    return {
      phone: null,
      loading: false,
      resending: false,
      resent: false,
      resendInterval: null,
      timeout: 60
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.auth.currentUser
    },
    cellPhoneConfirmationPending () {
      return this.currentUser && !this.currentUser.cell_phone_confirmed
    },
    disableResend () {
      return this.timeout > 0 || this.resending
    }
  },
  mounted () {
    this.resendInterval = setInterval(() => {
      if (this.timeout > 0) {
        this.timeout -= 1
      }
    }, 1000)
  },
  methods: {
    whoAmI () {
      this.$store.dispatch('auth/whoami')
    },
    resetTimeout () {
      this.timeout = 60
    },
    resend () {
      if (this.disableResend) {
        return
      }
      this.$refs.phoneConfirmationPopup.show()
    },
    dismiss () {
      this.$emit('close')
    }
  },
  destroyed () {
    if (this.resendInterval) {
      clearInterval(this.resendInterval)
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-phone {
  align-items: center;
  bottom: 56px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  flex-wrap: wrap;
  justify-content: center;
  left: 0;
  padding: 8px 40px 8px 16px;
  position: fixed;
  width: 100%;
  z-index: 100;

  @media (min-width: 960px) {
    bottom: 0;
  }

  .alert-link {
    text-decoration: underline;
    white-space: nowrap;

    &.al-disabled {
      opacity: .7;
      text-decoration: none;
    }
  }

  .alert-close {
    padding: 16px;
    position: absolute;
    right: 0;
  }
}
</style>
